import React, { useEffect } from 'react';
import GoogleAnalytics from '../GoogleAnalytics';
import { Link } from 'react-router-dom';

export default function LiveWarning({
  isLive,
  title,
  linkPalestra,
  toggleWarning,
}) {
  function closeWarning() {
    toggleWarning();
  }

  useEffect(() => {
    if (isLive) {
      document.body.classList.add('has-warning');
    } else {
      document.body.classList.remove('has-warning');
    }
  }, [isLive]);

  return (
    <div className={isLive ? 'live-warning is-active' : 'live-warning'}>
      <p className='live-warning__left'>ao vivo</p>
      <div className='live-warning__content'>
        <p className='live-warning__msg'>{title}</p>
        <div className='live-warning__right gtm-class'>
          <Link
            to={`/evento/${linkPalestra}`}
            className='live-warning__link'
            {...GoogleAnalytics.trackEvent(
              'lp-worktalks:interacao',
              `link:${linkPalestra}:assistir-agora`
            )}
          >
            Assista agora
          </Link>
          <button
            className='live-warning__close btn btn-link d-none d-lg-inline-block'
            onClick={closeWarning}
          >
            <img
              className='card-author__elipsis'
              src={require('../../assets/images/icon-close.svg')}
              alt='ícone fechar'
            />
          </button>
        </div>
      </div>
    </div>
  );
}
