import React from 'react';

import { Switch, Route } from 'react-router-dom';

import Home from '../views/Home';
import SingleEvent from '../views/SingleEvent';
import Resultados from '../views/Resultados';

export default function TheRouter({ metaTags, setMetaTags }) {
  return (
    <Switch>
      <Route exact path='/'>
        <Home />
      </Route>
      <Route path='/resultados'>
        <Resultados />
      </Route>
      <Route path='/evento/:slug'>
        <SingleEvent metaTags={metaTags} setMetaTags={setMetaTags} />
      </Route>
    </Switch>
  );
}
