export const content = {
  search: {
    historyBackText: 'Voltar',
    text: 'Resultados de busca',
    resultTextInitial: 'Encontramos',
    resultTextFinal: 'resultados para',
  },
  newSearch: {
    title: 'Não encontrou o que procurava?',
    subtitle: 'Faça uma nova busca:',
    searchBarPlaceholder: 'Busque por um tema ou palavra-chave ',
  },
};
