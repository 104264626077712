import 'react-app-polyfill/ie11';
import React from 'react';
import { hydrate, render } from "react-dom";
import { BrowserRouter as Router } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import rootReducer from './store/index';
import './assets/styles/main.scss';
import App from './App';

const rootElement = document.getElementById("root");
const store = createStore(rootReducer, applyMiddleware(thunk));
const rootProvider = (
  <>
    <Provider store={store}>
      <Router  basename={process.env.PUBLIC_URL}>
        <App />
      </Router>
    </Provider>
  </>
);

if (rootElement.hasChildNodes()) {
  hydrate(rootProvider, rootElement);
} else {
  render(rootProvider, rootElement);
}