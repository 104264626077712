import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { animateScroll as scroll } from "react-scroll";
import SingleHeader from "../../section/SingleHeader";
import SinglePreview from "../../section/SinglePreview";
import SingleContent from "../../section/SingleContent";
import SingleCarousel from "../../section/SingleCarousel";
import NewsLetter from "../../section/NewsLetter";
import ButtonScrollTop from "../../components/ButtonScrollTop";
import ModalShare from "../../components/ModalShare";
import GoogleAnalytics from "../../components/GoogleAnalytics";
import { Helmet } from "react-helmet";

function SingleEvent({ contents, metaTags, setMetaTags }) {
  
  let { slug } = useParams();
  const [modalShareActive, setModalShareActive] = useState(false);

  useEffect(() => {
    scroll.scrollToTop({ duration: 20 });
  });

  const findEvents = contents.map((content) => {
    return !content.disabled
      ? content.events.find((event) => event.slug === slug)
      : [];
  });

  const foundEvent = findEvents.filter(function (item) {
    return item !== undefined;
  });
  
  const event = foundEvent ? foundEvent[0] : false;

  if (event !== undefined && event.week !== undefined) {
    var page = `/worktalks/conteudo-semana-${event.week}/${slug}`;

    if (modalShareActive) {
      page += '-compartilhar';
    }

    GoogleAnalytics.trackPageview(page);
  }

  return (
    <>
      {event ? (
        <div className="container">
          <Helmet onChangeClientState={(h) => { setMetaTags(h.metaTags) }}>
            <meta property="og:title" content={event.shortDescription} />
            <meta property="og:image" content={event.bgImg} />
          </Helmet>
          <SingleHeader
            title={event.shortDescription}
            type={event.type}
            typeDisplay={event.typeDisplay}
            datetime={event.type === 'palestra' ? event.livetime : event.datetime}
          />
          <div className="container-single">
            <SinglePreview
              likes={event.info.likes}
              views={event.info.views}
              comments={event.info.comments}
              type={event.type}
              iFrameUrl={event.iframeUrl}
              setModalShareActive={setModalShareActive}
            />
            <SingleContent
              speaker={event.speaker}
              description={event.description}
              seeMore={event.seeMore}
            />
          </div>
          {event.relatableContent >= 1 ? (
            <SingleCarousel events={event.relatableContent} />
          ) : null}
        </div>
      ) : null}
      <NewsLetter />
      <ButtonScrollTop />
      {modalShareActive ? (
      <ModalShare
        isActive={modalShareActive}
        toggleModal={() => {
          setModalShareActive(false);
        }}
        metaTags={metaTags}
      />
      ) : null}
    </>
  );
}

function mapStateToProps(state) {
  return {
    ...state.content,
  };
}

export default connect(mapStateToProps)(SingleEvent);
