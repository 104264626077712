import React from "react";
import Modal from "react-bootstrap/Modal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import GoogleAnalytics from "../GoogleAnalytics";

export default function ModalShare({ isActive, toggleModal, metaTags }) {

  function getUrlLink() {
    return window.location.href;
  }

  function getUrlLinkEncoded() {
    return encodeURIComponent(window.location.href);
  }

  function getTitle() {
    return encodeURIComponent('Worktalks - '.concat(metaTags['og:title']));
  }
  
  function getText() {
    return encodeURIComponent(metaTags['og:description']);
  }

  function twitterLinkShare() {
    return `https://twitter.com/intent/tweet?text=${getTitle()}&url=${getUrlLinkEncoded()}`;
  }

  function linkedinLinkShare() {
    let width = 500;
    let height = 300;
    let left = window.screen.width / 2 - width / 2;
    let top = window.screen.height / 2 - height / 2;
    let url = getUrlLinkEncoded();
    window.open(
      `https://www.linkedin.com/shareArticle?url=${url}&title=${getTitle()}&summary=${getText()}`,
      "Compartilhar",
      `width=${width},height=${height},top=${top},left=${left}`
    );
  }

  function facebookShare() {
    let width = 500;
    let height = 300;
    let left = window.screen.width / 2 - width / 2;
    let top = window.screen.height / 2 - height / 2;
    let url = getUrlLinkEncoded();
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      "Compartilhar",
      `width=${width},height=${height},top=${top},left=${left}`
    );
  }

  function mailtoLinkShare() {
    let body = encodeURIComponent(`Olá,<br><br>`);
    body = body.concat(getText());
    body = body.concat(encodeURIComponent('<br><br>'));
    body = body.concat(getUrlLinkEncoded());
    return `mailto:?subject=${getTitle()}&body=${body}`
  }

  return (
    <Modal
      show={isActive}
      onHide={toggleModal}
      centered
      className="modal modal--share"
    >
      <div className="modal__content bg-blue">
        <button className="modal__close" onClick={toggleModal}>
          <img
            src={require("../../assets/images/icon-cross.svg")}
            alt="Fechar modal"
          />
        </button>

        <h2 className="modal--share__title">Compartilhar:</h2>
        <ul className="modal--share__social-list text-lg-center gtm-class">
          <li>
            <button
              onClick={() => facebookShare()}
              {...GoogleAnalytics.trackEvent(
                "lp-worktalks:interacao",
                "link:compartilhar:facebook"
              )}
            >
              <img
                src={require("../../assets/images/icon-circle-facebook.svg")}
                alt="facebook"
              />
            </button>
          </li>
          <li>
            <button
              onClick={() => linkedinLinkShare()}
              {...GoogleAnalytics.trackEvent(
                "lp-worktalks:interacao",
                "link:compartilhar:linkedin"
              )}
            >
              <img
                src={require("../../assets/images/icon-circle-instagram.svg")}
                alt="Linkedln"
              />
            </button>
          </li>
          <li>
            <a
              href={twitterLinkShare()}
              target="_blank"
              {...GoogleAnalytics.trackEvent(
                "lp-worktalks:interacao",
                "link:compartilhar:twitter"
              )}
              rel="noopener noreferrer"
            >
              <img
                src={require("../../assets/images/icon-circle-twitter.svg")}
                alt="twitter"
              />
            </a>
          </li>
          <li>
            <a
              href={mailtoLinkShare()}
              target="_blank"
              {...GoogleAnalytics.trackEvent(
                "lp-worktalks:interacao",
                "link:compartilhar:email"
              )}
              rel="noopener noreferrer"
            >
              <img
                src={require("../../assets/images/icon-circle-email.svg")}
                alt="email"
              />
            </a>
          </li>
        </ul>

        <h2 className="modal--share__title">Link:</h2>
        <div className="modal--share__box-link">{getUrlLink()}</div>
        <div className="text-right">
          <CopyToClipboard
            className="modal--share__btn-copy btn btn-link gtm-class"
            text={getUrlLink()}
          >
            <button
              className="modal--share__btn-copy btn btn-link"
              {...GoogleAnalytics.trackEvent(
                "lp-worktalks:interacao",
                "link:compartilhar:copiar-link"
              )}
            >
              Copiar link{" "}
              <img
                src={require("../../assets/images/icon-chain.svg")}
                alt="ícone compartilhar"
                className="ml-2"
              />
            </button>
          </CopyToClipboard>
        </div>
      </div>
    </Modal>
  );
}
