import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { content } from '../../config/global';
import { validation } from '../../config/validation';
import MaskedInput from 'react-input-mask';
import GoogleAnalytics from '../GoogleAnalytics';
import API from '../../api';
import {
  Checkbox
} from '@catho/quantum';


export default function ModalRegister({
  isActive,
  toggleModal,
  showView,
  setShowView,
}) {

  return (
    <Modal
      show={isActive}
      onHide={toggleModal}
      centered
      className='modal modal--register'
    >
      <div className='modal__content bg-pink'>
        <button className='modal__close' onClick={toggleModal}>
          <img
            src={require('../../assets/images/icon-cross.svg')}
            alt='Fechar modal'
          />
        </button>
        {showView === 'form' ? (
          <ModalForm setShowView={setShowView} />
        ) : (
          <ModalSuccess toggleModal={toggleModal} setShowView={setShowView} />
        )}
      </div>
    </Modal>
  );
}

function ModalForm({ setShowView }) {
  const {
    nameText,
    emailText,
    submitBtnText,
    telText,
    terms
  } = content.modal.register;
  const { register, handleSubmit, errors, reset } = useForm();
  var [error, setError] = useState(false);
  var [buttonDisabled, setButtonDisabled] = useState(false);
  const [termsAccept, setTermsAccept] = useState(false);

  const handleClick = (e) => {
    setError(false);
  };

  const onSubmit = async (data) => {
    var form = new FormData(document.getElementById('registerForm'));
    setButtonDisabled(true);

    try {
      await API.register(form);
      reset();
      setShowView('feedBack');
    } catch (e) {
      setError(true);
    }

    setButtonDisabled(false);
  };

  return (
    <form
      id='registerForm'
      onSubmit={handleSubmit(onSubmit)}
      className='modal__form gtm-class'>

      <label htmlFor='name' className='modal__label mb-5'>
        {nameText}
      </label>
      <input
        name='name'
        type='text'
        ref={register({ required: true })}
        className='modal__input'
        {...GoogleAnalytics.trackEvent('lp-worktalks:formulario', 'onblur:nome')}
      />
      {errors.name && (
        <div className="alert alert-danger mb-5">O nome é obrigatório.</div>
      )}

      <label htmlFor='email' className='modal__label'>
        {emailText}
      </label>
      <input
        name='email'
        type='email'
        className='modal__input'
        ref={register({
          required: true,
          pattern: validation.email,
        })}
        {...GoogleAnalytics.trackEvent('lp-worktalks:formulario', 'onblur:email')}
      />
      {errors.email && (
        <div className="alert alert-danger mb-5">O e-mail é inválido.</div>
      )}

      <label htmlFor='tel' className='modal__label'>
        {telText}
      </label>
      <MaskedInput
        name='phone'
        className='modal__input'
        inputRef={register({
          required: true,
          pattern: validation.phone,
        })}
        mask='(99) 99999-9999'
        {...GoogleAnalytics.trackEvent('lp-worktalks:formulario', 'onblur:telefone')}
      />
      {errors.phone && (
        <div className="alert alert-danger">O telefone é obrigatório.</div>
      )}
      {error && (
        <div className="alert alert-danger">{content.register.messages.error}</div>
      )}

      <div htmlFor="terms" className="modal__terms">
        <Checkbox id="terms" error={errors.terms && 'Aceitar os termos é obrigatório'} value={terms} checked={termsAccept} onChange={(e) => setTermsAccept(e.target.checked)} name="terms"
          {...GoogleAnalytics.trackEvent('lp-worktalks:formulario', 'check:terms')}
        /> 
        <a href="https://assets.catho.com.br/consents-prod/terms/politica-cookies-privacidade.html" target="_blank" rel="noopener noreferrer"
          {...GoogleAnalytics.trackEvent('lp-worktalks:formulario', 'link:terms')}
        >termos e condições de uso.</a>
      </div>

      <div className='d-flex justify-content-center'>
        <button
          type='submit'
          className={`text-uppercase bg-blue color-white btn btn--default ${buttonDisabled || !termsAccept ? 'disabled' : ''}`}
          disabled={buttonDisabled || !termsAccept}
          onClick={handleClick}
          {...GoogleAnalytics.trackEvent('lp-worktalks:formulario', 'botao:enviar')}
        >
          {submitBtnText}
        </button>
      </div>
    </form>
  );
}

function ModalSuccess({ toggleModal, setShowView }) {
  const { title, text, btnText } = content.modal.registerFeedback;

  function handleClick() {
    toggleModal();
    setShowView('form');
  }

  return (
    <div className='modal__feedback'>
      <p className='modal__feedback-title color-white text-center'>{title}</p>
      <p className='modal__feedback-subtitle mb-10 color-white text-center'>
        {text}
      </p>

      <div className='d-flex justify-content-center gtm-class'>
        <button
          type='button'
          className='text-uppercase bg-blue color-white btn--default'
          onClick={handleClick}
          {...GoogleAnalytics.trackEvent('lp-worktalks:formulario', 'botao:fechar')}
        >
          {btnText}
        </button>
      </div>
    </div>
  );
}
