import React from 'react';
import Moment from 'react-moment';
import 'moment-timezone';

export default function DateTime({ datetime, className }) {
  return (
    <div
      className={`btn btn--small btn--round datetime bg-white color-black ${className}`}
    >
      <span className=''>
        <img
          src={require('../../assets/images/icon-calendar.svg')}
          className='datetime__calendar'
          alt='Calendário'
        />
        <Moment tz='America/Sao_Paulo' format='DD/MM'>
          {datetime}
        </Moment>
      </span>
      <span>
        <img
          src={require('../../assets/images/icon-elipse.svg')}
          alt='Elipse'
          className='datetime__elipse'
        />
        <Moment tz='America/Sao_Paulo' format='HH:mm'>
          {datetime}
        </Moment>
      </span>
    </div>
  );
}
