import React from 'react';
import { useForm } from 'react-hook-form';
import { content } from '../../config/global';
import { validation } from '../../config/validation';
import MaskedInput from 'react-input-mask';
import GoogleAnalytics from '../../components/GoogleAnalytics';
import API from '../../api';

export default function NewsLetter({ toggleModal }) {

  const {
    title,
    btnText,
    inputEmailPlaceholder,
    inputNamePlaceholder,
    inputTelPlaceholder,
  } = content.newsLetter;

  const {
    terms
  } = content.modal.register;

  const { register, handleSubmit, errors, reset } = useForm();

  var [error, setError] = React.useState(false);
  var [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [termsAccept, setTermsAccept] = React.useState(false);

  const handleClick = (e) => {
    setError(false);
  };

  const onSubmit = async (data, e) => {
    var form = new FormData(document.getElementById('newsletterForm'));
    setButtonDisabled(true);

    try {
      await API.register(form);
      reset();
      toggleModal();
    } catch (e) {
      setError(true);
    }

    setButtonDisabled(false);
  };

  return (
    <section className='newsletter bg-pink'>
      <div className='newsletter__content'>
        <h3 className='newsletter__title mb-5 mb-lg-8 color-white'>{title}</h3>
        <form
          id='newsletterForm'
          className='form-control newsletter__form gtm-class'
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="form-row mb-3 mb-lg-0">
            <input
              name='name'
              ref={register({ required: true })}
              type='text'
              placeholder={inputNamePlaceholder}
              className='input-text input-text--bg-white'
              {...GoogleAnalytics.trackEvent('lp-worktalks:formulario', 'onblur:conteudo:nome')}
            />
            {errors.name && (
              <div className="alert alert-danger">O nome é obrigatório.</div>
            )}
          </div>
          <div className="form-row mb-3 mb-lg-0">
            <input
              name='email'
              ref={register({
                required: true,
                pattern: validation.email,
              })}
              type='text'
              placeholder={inputEmailPlaceholder}
              className='input-text input-text--bg-white'
              {...GoogleAnalytics.trackEvent('lp-worktalks:formulario', 'onblur:conteudo:email')}
            />
            {errors.email && (
              <div className="alert alert-danger">O e-mail é inválido.</div>
            )}
          </div>
          <div className="form-row mb-3 mb-lg-0">
            <MaskedInput
              name='phone'
              className='input-text input-text--bg-white'
              inputRef={register({
                required: true,
                pattern: validation.phone,
              })}
              placeholder={inputTelPlaceholder}
              mask='(99) 99999-9999'
              {...GoogleAnalytics.trackEvent('lp-worktalks:formulario', 'onblur:conteudo:telefone')}
            />
            {errors.phone && (
              <div className="alert alert-danger">O telefone é obrigatório.</div>
            )}
          </div>
          <div htmlFor="terms" className="newsletter__form__terms">
            <input
              id="terms"
              type="checkbox"
              value={terms}
              checked={termsAccept}
              onChange={(e) => setTermsAccept(e.target.checked)}
              name="terms"
              ref={register({
                required: true,
              })}
              {...GoogleAnalytics.trackEvent('lp-worktalks:formulario', 'check:conteudo:termos')}
            />
            <label htmlFor="terms">
              <span>
                {terms}
              </span>
              <a href="https://assets.catho.com.br/consents-prod/terms/politica-cookies-privacidade.html" target="_blank" rel="noopener noreferrer" {...GoogleAnalytics.trackEvent('lp-worktalks:formulario', 'link:conteudo:termos')} >termos e condições de uso.</a>
            </label>

            {errors.terms && (
              <div className="alert alert-danger">Aceitar os termos é obrigatório.</div>
            )}
          </div>
          <div className="form-row mt-3 mt-lg-0">
            <button
              type='submit'
              className='btn btn--medium bg-blue color-white'
              disabled={buttonDisabled}
              onClick={handleClick}
              {...GoogleAnalytics.trackEvent('lp-worktalks:formulario', 'botao:conteudo:enviar')}
            >
              {btnText}
            </button>
          </div>
        </form>
        {error && (
          <div className="alert alert-danger alert-row">{content.register.messages.error}</div>
        )}
      </div>
    </section>
  );
}
