// Types
export const Types = {
  SET_CONTENT: "content/SET_CONTENT",
};

// Reducer
const initialState = {
  contents: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.SET_CONTENT:
      return {
        ...state,
        contents: action.payload.data,
      };
    default:
      return state;
  }
}

// Actions
export function setContent() {
  return {
    type: Types.SET_CONTENT,
    payload: {
      data: [
        {
          week: 1,
          events: [
            // Palestra 01
            {
              id: "01",
              bgImg: require("../assets/post/palestra-1/palestra-01.png"),
              slug: "mercado-de-trabalho-como-conseguir-o-emprego-ideal-pra-voce", //unico
              type: "palestra",
              typeDisplay: "palestra",
              datetime: "2022-05-02 18:00:00", // para a página ser liberada
              livetime: "2022-05-02 18:00:00", // para exibição da barra de live
              duration: "60",
              week: 1,
              shortDescription:
                "Mercado de trabalho: como conseguir o emprego ideal pra você!",
              description: ``,
              iframeUrl: "https://www.youtube.com/embed/35nzGp0eQig",
              info: {
                views: "200",
                likes: "20",
                comments: "2",
              },
              speaker: [
                {
                  name: "Renata Rivetti",
                  avatarImg: require("../assets/post/palestra-1/autor-1-palestra-01.jpg"),
                  jobTitle: "Diretora da Reconnect",
                  type: "Sobre os palestrantes", // palestrante, autora, escritor
                  description: `
                    <p>Graduada em Administração pela FGV-EAESP, com Pós-Graduação em Psicologia Positiva pela PUC-RS e Especialização em Estudos da Felicidade na Happiness Studies Academy. Atuou como gerente de marketing por muitos anos em grandes empresas e multinacionais e em 2017 fez uma transição de carreira, passando a atuar no desenvolvimento de pessoas, se especializando no tema da Felicidade no Trabalho. Atualmente, atua como diretora e consultora da Reconnect | Happiness at Work, palestrante e professora do MBA Isec Lisboa de Felicidade Organizacional.</p>
                  `,
                },
                {
                  name: "Fernanda Araujo",
                  avatarImg: require("../assets/post/palestra-1/autor-2-palestra-01.png"),
                  jobTitle:
                    "Gerente Sr. de Marketing Catho",
                  type: "Sobre os palestrantes", // palestrante, autora, escritor
                  description: `
                    <p>Executiva de Marketing com experiência em Desenvolvimento de Negócios, Posicionamento de Marca e CX, atua há mais de 20 anos liderando equipes de alta performance. Graduada pela PUC-RJ,fez MBA em Marketing pela ESPM e cursos de extensão na área nas Universidades de Columbia e Boston. Ser humano em eterna reforma, querendo aprender e buscando o que há de melhor em cada um.</p>
                    `,
                },
                {
                  name: "Marcelo Nóbrega",
                  avatarImg: require("../assets/post/palestra-1/autor-3-palestra-01.jpg"),
                  jobTitle:
                    "Especialista em Inovação e Tecnologia da Falconi",
                  type: "Sobre os palestrantes", // palestrante, autora, escritor
                  description: `
                    <p>Executivo com 30 anos de experiência em empresas dos setores financeiro, de petróleo e gás, bens de consumo, serviço de transporte aéreo e alimentício. É especialista em coaching de executivos, gestão da mudança e desenvolvimento organizacional e de lideranças. Suas experiências profissionais incluem projetos de transformação de estratégia e cultura corporativa em empresas multinacionais, tanto no Brasil, como na América Latina e nos EUA, no contexto de aquisições, fusões, spin-offs e startups. Hoje faz parte dos conselhos da Vee Benefícios Flexíveis, HSPW, Revista HSM Management e INNITI Executive Search. É investidor anjo das HRTechs Kludo, Allya, Pin People, 7Waves e Sami Health.</p>
                    <p>À frente do RH do McDonald's no Brasil, Marcelo recebeu prêmios e reconhecimentos pela sua gestão tais como: Great Place to Work, Great Place to Work para Mulheres, Top Employer, Melhores Empresas para se Trabalhar da Você S/A, Women in Leadership in Latin America da Revista Exame, Selo de Diversidade Étnico-Racial da Prefeitura de Salvador, Prêmio Melhores Empresas para Trabalhadores com Deficiência do Estado de São Paulo/ONU e o Prêmio Apolo de apoio a pessoas LGBT do Estado do Mato Grosso do Sul. Em 2018, foi eleito o dirigente de RH Top of Mind, RH mais Influente da América Latina e Top Voice do LinkedIn.</p>
                    <p>Possui bacharelado e mestrado em Ciência da Computação pela Universidade de Columbia, em Nova York, e doutorado em Engenharia de Produção pela COPPE/UFRJ. É autor de artigos científicos e do livro “Você Está Contratado!”. É professor e palestrante em cursos de graduação e pós-graduação de gestão estratégica e inovação em recursos humanos.</p>
                    `,
                },
              ],
              seeMore: [
                // Audio 01
                // {
                //   id: "01",
                //   slug: "geracao-z-qualificacoes-que-podem-diferenciar-os-jovens",
                //   bgImg: require("../assets/post/audio-1/audio-01.png"),
                //   type: "audio",
                //   datetime: "2021-05-03 17:00:00",
                //   shortDescription:
                //     "Geração Z: qualificações que podem diferenciar os jovens",
                // },
                // Artigo 01
                {
                  id: "02",
                  slug: "emprego-novo-em-3-passos",
                  bgImg: require("../assets/post/artigo-1/artigo-01.jpg"),
                  type: "artigo",
                  datetime: "2022-05-02 18:00:00",
                  shortDescription:
                    "Emprego novo em 3 passos",
                },
              ],
            },
            // Audio 1
            /* {
              id: "02",
              bgImg: require("../assets/post/audio-1/audio-01.png"),
              slug: "geracao-z-qualificacoes-que-podem-diferenciar-os-jovens", //unico
              type: "podcast",
              typeDisplay: "áudio",
              datetime: "2021-05-03 17:00:00",
              duration: "30",
              week: 1,
              shortDescription:
                "Geração Z: qualificações que podem diferenciar os jovens",
              description: `
                <audio controls>
                  <source src="${require("../assets/post/audio-1/audio-1.mp3")}" type="audio/mp3">
                  Your browser does not support the audio element.
                </audio>
                <p>Ao buscar por <strong>qualificações</strong>, o profissional traz o fundamental para se tornar destaque em meio à concorrência do mercado de trabalho. Saber mexer em ferramentas, softwares ou ter aquele “algo a mais” no currículo pode aumentar as chances de uma entrevista de emprego de conquistar a vaga tão desejada.</p>
                <p>Mas se engana quem acredita que a boa qualificação é apenas com aqueles aprendizados específicos para uma determinada área, pois existem conteúdos que são universais e chamativos em todos os cargos, independentemente da área de atuação.</p>
                <p>Então, pensando exatamente em como se qualificar sem seguir um caminho em direção a uma área específica, separamos alguns assuntos interessantes para o CV de todos que buscam oportunidades.</p>
                <h4>Qualificações para dar um up em todos os currículos</h4>
                <p>Independente de idade, função e carreira que pretende seguir, essas nossas indicações podem tornar um currículo mais chamativo aos olhos dos <strong>recrutadores</strong>, já que uma boa qualificação enriquece o candidato.</p>
                <p>Veja agora algumas dicas de assuntos legais para se preparar para o mercado de trabalho e ser um profissional qualificado:</p>
                <h4>Pacote de aplicativos para escritório</h4>
                <p>Esses pacotes são voltados para auxiliar os profissionais em suas tarefas dentro do escritório ao utilizar seus computadores. Eles contam com programas voltados para processador de texto, planilha de cálculo, banco de dados, apresentação gráfica, disparo de e-mails e entre outros.</p>
                <p>Para aprender a mexer em todos, é interessante buscar cursos de qualificações e afins que ensinam em conjunto, isto é, que vão mostrar ferramenta por ferramenta e unificar a qualificação em um só lugar.</p>
                <p>É importante escolher os pacotes mais populares usados nas empresas e sempre buscar atualização, já que as marcas cada vez mais lançam novidades.</p>
                <h4>Criação de imagens</h4>
                <p>A criação de imagem não é só voltada para quem trabalha com comunicação, como os designers, por exemplo, mas também pode ser bastante útil para diversas outras áreas.</p>
                <p>Saber escolher, criar uma arte gráfica ou modificar uma imagem pode ajudar muito na hora de elaborar a nossa próxima indicação: a apresentação. Isto se dá porque nem sempre encontramos aquela foto ou vetor do jeito certo para mostrarmos da melhor maneira aquele dado para a equipe. E olha, isso faz muita diferença. Fazem com que você eleve a qualidade da informação usando formas de ilustrá-las.</p>
                <p>Então, procure se qualificar com aulas que ensinam a mexer em sites e programas que permitam fazer mudanças em imagens ou até mesmo criar suas peças gráficas desde o início. Ah! E existem várias ferramentas na internet que são gratuitas e fáceis, sabia?!</p>
                <h4>Boas apresentações</h4>
                <p>Como já mencionamos, mostrar os dados de uma pesquisa ou resultados de modo dinâmico e bem claro demonstra o seu bom desempenho, por isso, aprender a montar uma boa apresentação pode até te trazer uma promoção.</p>
                <p>Por isso, busque por cursos, aulas e vídeos voltados a mostrar como fazer incríveis apresentações nas plataformas gratuitas e de fácil aprendizado. Muitas vezes são essas apresentações que vão trazer visibilidade ao seu trabalho, aos resultados que você alcança e às ideias que você tem e implementa.</p>
                <h4>Comunicação escrita e criação de bons e-mails</h4>
                <p>Pode até parecer simples, mas nem sempre a elaboração de um e-mail é correta e tem uma comunicação assertiva. A falta de entendimento de uma mensagem pode gerar erros em escala, por exemplo. Já os acertos nas comunicações ajudam no andamento das tarefas feitas pela empresa e equipe.</p>
                <p>E para ficar craque em construir e-mails para diferentes assuntos e de uma maneira que seja eficaz para o time, faça uma qualificação voltada para a boa escrita, redação e interpretação de texto. Além também de existir conteúdos que ajudam a entender melhor sobre comunicação corporativa, como otimizar a comunicação e como usar as ferramentas de e-mail.</p>
                <p>Se você é um profissional no começo de carreira, atente-se a esses pontos, mas também busque identificar suas aptidões e desejos profissionais para focar seus conhecimentos e aprendizados. Busque se qualificar para o mercado de trabalho considerando o seu momento atual e também o que deseja a médio e longo prazo.</p>
                <p>E aí, você sabe qual curso deveria existir para que todos os profissionais tenham boas qualificações? Nos conte nas redes sociais e compartilhe esse texto com seus amigos!</p>
              `,
              iframeUrl: require("../assets/post/audio-1/audio-01.png"),
              info: {
                views: "200",
                likes: "20",
                comments: "2",
              },
              speaker: [
                // {
                //   name: "Jacqueline Carmo",
                //   avatarImg: require("../assets/post/audio-1/autor-audio-1.png"),
                //   jobTitle: "Analista de Conteúdo Pleno",
                //   type: "Sobre a autora", // palestrante, autora, escritor
                //   description:
                //     "Com formação em Marketing, especializou-se em comunicação institucional e publicitária com foco em mídias sociais e produção, curadoria, edição e finalização de conteúdos. Nos últimos anos, dedica-se a colunas de atualidades, mercado de trabalho, negócios e empreendedorismo.",
                // },
                {
                  name: "Fernanda Zoubaref",
                  avatarImg: require("../assets/post/artigo-1/autor-artigo-1.jpg"),
                  //jobTitle: "Analista de Conteúdo Pleno",
                  type: "Sobre a autora", // palestrante, autor, escritor
                  description: `
                    <p>Jornalista, formada também em Comunicação Visual, está na Catho há mais de um ano e começou suas atividades na empresa como redatora do Blog da Catho Educação. Desde então, cria conteúdos para as redes sociais, blog, atendimento e auxilia em processos de campanhas.</p>
                    <p>Em seu passado profissional, já produziu um programa para TV, trabalhou com acessibilidade, foi fotógrafa e hoje se dedica aos aprendizados da área de Marketing.</p>
                  `,
                },
              ],
              relatableContent: [],
              seeMore: [
                // Palestra 01
                {
                  id: "01",
                  slug: "mercado-de-trabalho-como-conseguir-o-emprego-ideal-pra-voce",
                  bgImg: require("../assets/post/palestra-1/palestra-01.jpg"),
                  type: "palestra",
                  datetime: "2021-05-03 17:00:00",
                  shortDescription:
                    "Mercado de trabalho: como conseguir o emprego ideal pra você!",
                },
                // Artigo 01
                {
                  id: "02",
                  slug: "emprego-novo-em-3-passos",
                  bgImg: require("../assets/post/artigo-1/artigo-01.jpg"),
                  type: "artigo",
                  datetime: "2022-05-02 17:00:00",
                  shortDescription:
                    "Emprego novo em 3 passos",
                },
              ],
            }, */
            // Artigo 01
            {
              id: "03",
              bgImg: require("../assets/post/artigo-1/artigo-01.jpg"),
              slug: "emprego-novo-em-3-passos", //unico
              type: "artigo",
              typeDisplay: "artigo",
              datetime: "2022-05-02 18:00:00",
              duration: "30",
              week: 1,
              shortDescription:
                "Emprego novo em 3 passos",
              description: `
                <h1>Emprego novo em 3 passos</h1>
                
                <p>Procurar emprego, está aí uma atividade que todos nós conhecemos bem! Por ser tão comum, parece algo simples, mas não é não.  Se tem uma coisa que dá trabalho é procurar trabalho! Mas calma, temos algumas dicas, importantes, para que você fique a poucos passos do seu emprego novo.</p>
                <p><strong>Passos para conquistar o emprego novo.</strong></p>
                <p><strong>1. Planeje</strong></p>
                <p>O primeiro passo para uma busca de emprego de sucesso, é planejar como será esse processo. Nesse momento, pense em três fatores: Onde, Quando e Como.</p>
                <p>Onde você quer trabalhar? Claro que na hora do aperto pouco se escolhe, mas pelo menos dê prioridade para organizações que você deseja trabalhar por algum motivo. Quando? Que dias da semana e horário você irá procurar? Lembre-se que há processos que encerram-se em dias, por isso o ideal é procurar, no mínimo, três vezes por semana. Como? Em que locais você irá procurar? Sites, contatar amigos e conhecidos? Defina como será a sua procura.</p>
                <p>Depois de ter as respostas monte uma tabela com os locais que irá procurar, organizações alvo e os dias da semana. Parece demais, mas essa tabela vai te ajudar e muito! Uma dica de ouro aqui é assinar a Catho. Mais de 300 mil vagas e várias facilidades em sua rotina de buscas.</p>
                <p><strong>2. Tenha um currículo campeão</strong></p>
                <p>O seu currículo é a peça chave para o seu sucesso profissional, afinal ele é o primeiro contato entre o recrutador e você. Mas você sabe montar um bom currículo? Tem certeza que todas as informações estão dispostas no seu documento?</p>
                <p>De uma forma bem resumida, você DEVE colocar em seu documento: dados pessoais, objetivo, resumo de qualificações, experiência profissional, formação acadêmica e finalize com informações adicionais como cursos complementares, prêmios, certificações, por exemplo.</p>
                <p>Confira aqui alguns modelos prontos. E se você prefere não se arriscar, não se preocupe, a Catho oferece um serviço de elaboração de currículo, profissionais de RH e psicólogos que entendem a sua carreira e elaboram o seu currículo. Saiba mais. </p>
                <p><strong>3. Treine</strong></p>
                <p>Após planejar a forma como irá buscar emprego e dar prioridade para o seu currículo, chegou a hora de se preparar para os convites de entrevista. Normalmente, o que será pedido será a sua história pessoal e profissional, ambas têm o mesmo peso, mas o ideal é que você mencione aspectos pessoais que contribuíram para sua carreira.</p>
                <p>Um exercício bacana é escrever mesmo a sua história, passe para um papel fica muito mais fácil. Depois é só estudar, não precisa decorar, a história é sua, ninguém contaria melhor que você!</p>
                <p>E caso você precise de uma ajuda profissional, conte com a Catho para te ajudar também. Com o serviço de simulação de entrevista, você vivencia uma entrevista e é avaliado por profissionais que entendem tudo sobre processo seletivo. Veja aqui.</p>
                <p>Agora que você já sabe dos passos mais importantes para começar em um novo emprego, aproveite e compartilhe com seus amigos. Ah! E partiu emprego novo!</p>
                `,
              iframeUrl: require("../assets/post/artigo-1/artigo-01-interna.png"),
              info: {
                views: "200",
                likes: "20",
                comments: "2",
              },
              speaker: [
                {
                  name: "Maria Eduarda Goncalves Pereira",
                  avatarImg: require("../assets/post/artigo-1/autor-artigo-1.jpg"),
                  //jobTitle: "Analista de Conteúdo Pleno",
                  type: "Sobre a autora", // palestrante, autor, escritor
                  description: `
                    <p>Jovem aprendiz no setor de Marketing Mídia pela empresa Catho, desenvolvendo conteúdos para o blog da empresa. Trabalhou em outra organização na área de design e atendimento. Motivada a crescer profissionalmente e ganhar conhecimento.</p>
                  `,
                },
              ],
              relatableContent: [],
              seeMore: [
                // Palestra 01
                {
                  id: "01",
                  slug: "mercado-de-trabalho-como-conseguir-o-emprego-ideal-pra-voce",
                  bgImg: require("../assets/post/palestra-1/palestra-01.png"),
                  type: "palestra",
                  datetime: "2022-05-02 18:00:00",
                  shortDescription:
                    "Mercado de trabalho: como conseguir o emprego ideal pra você!",
                },
                // Áudio 01
                // {
                //   id: "02",
                //   slug: "geracao-z-qualificacoes-que-podem-diferenciar-os-jovens",
                //   bgImg: require("../assets/post/audio-1/audio-01.png"),
                //   type: "áudio",
                //   datetime: "2021-05-03 17:00:00",
                //   shortDescription:
                //     "Geração Z: qualificações que podem diferenciar os jovens",
                // },
              ],
            },
          ],
        },
        {
          week: 2,
          events: [
            // Palestra 02
            {
              id: "04",
              bgImg: require("../assets/post/palestra-2/palestra-02.png"),
              slug: "vagas-em-tecnologia-dicas-de-um-head-hunter-para-conseguir-a-sua", //unico
              type: "palestra",
              typeDisplay: "palestra",
              datetime: "2022-05-09 18:00:00", // para a página ser liberada
              livetime: "2022-05-09 18:00:00", // para exibição da barra de live
              duration: "60",
              week: 2,
              shortDescription:
                "Vagas em tecnologia: dicas de um head hunter para conseguir a sua!",
              description: ``,
              iframeUrl: "https://www.youtube.com/embed/zASLol-AnMY",
              info: {
                views: "200",
                likes: "20",
                comments: "2",
              },
              speaker: [
                {
                  name: "Marcela Azambuja",
                  avatarImg: require("../assets/post/palestra-2/autor1-palestra-2.jpg"),
                  jobTitle: "Diretora de Recursos Humanos da Let's Code",
                  type: "Sobre os palestrantes", // palestrante, autor, escritor
                  description: `Engenheira politécnica, atua há mais de 12 anos na área de recursos humanos, tendo passado por empresas como Maersk, Shell e Philips. Há pouco mais de um ano e meio veio para a Let's Code, empresa que atua na área de educação em programação, para atuar no mundo das startups.
                  `,
                },
                {
                  name: "Eber Duarte",
                  avatarImg: require("../assets/post/palestra-2/autor2-palestra-2.jpg"),
                  jobTitle: "Diretor de Tecnologia Catho",
                  type: "Sobre os palestrantes", // palestrante, autor, escritor
                  description:
                    "Graduado em Ciência da Computação e mestre em Engenharia Elétrica, com ênfase em Inteligência Artificial, trabalhou como Desenvolvedor de sistemas em ambiente Web, atuou como especialista em banco de dados MySQL, tendo desenvolvido projetos de arquitetura e alta disponibilidade em MySQL. Na Catho já atuou em diversas frentes em Infraestrutura, liderando variadas áreas e no momento é responsável por toda a área de tecnologia, sendo responsável pela entrega de soluções para empresas e candidatos, bem como garantir a segurança e a estabilidade de todo o marketplace da Catho.",
                },
                {
                  name: "Sarah Rocha",
                  avatarImg: require("../assets/post/palestra-2/autor3-palestra-2.jpg"),
                  jobTitle: "Gerente de Recrutamento em Tecnologia",
                  type: "Sobre os palestrantes", // palestrante, autor, escritor
                  description: `
                    <p>Atua há mais de 15 anos na área de People, destes, 7 anos em empresas de tecnologia. É formada em Gestão de RH e Administração e pós-graduada em Psicologia Organizacional.</p>
                    <p>Hoje lidera o time de Tech Recruiters na Imagine Beyond, consultoria especializada em recrutamento e seleção de profissionais.</p>
                  `,
                },
              ],
              relatableContent: [],
              seeMore: [
                // Áudio 02
                // {
                //   id: "01",
                //   slug: "como-aproveitar-o-momento-de-alta-e-buscar-sua-vaga",
                //   bgImg: require("../assets/post/audio-2/audio-02.png"),
                //   type: "áudio",
                //   datetime: "2021-05-07 17:00:00",
                //   shortDescription:
                //     "Como aproveitar o momento de alta e buscar sua vaga.",
                // },
                // Artigo 02
                {
                  id: "02",
                  slug: "fique-na-mira-de-um-headhunter",
                  bgImg: require("../assets/post/artigo-2/artigo-02.jpg"),
                  type: "artigo",
                  datetime: "2022-05-09 18:00:00",
                  shortDescription:
                    "Fique na mira de um headhunter!",
                },
              ],
            },
            // Audio 2
            /* {
              id: "05",
              bgImg: require("../assets/post/audio-2/audio-02.png"),
              slug: "como-aproveitar-o-momento-de-alta-e-buscar-sua-vaga", // unico
              type: "podcast",
              typeDisplay: "áudio",
              datetime: "2021-05-07 17:00:00",
              duration: "4:06",
              week: 2,
              shortDescription:
                "Como aproveitar o momento de alta e buscar sua vaga.",
              description: `
              <audio controls>
                <source src="${require("../assets/post/audio-2/audio-2.mp3")}" type="audio/mp3">
                Your browser does not support the audio element.
              </audio>

              <p>Já fez mais de um ano que entramos em um isolamento social, imposto a grande parte das cidades brasileiras, e essencial para o combate à pandemia do novo Coronavírus. De imediato, as restrições causaram impacto em inúmeras áreas da atividade econômica, afetando diretamente o mercado de trabalho. Um desses efeitos é a adoção do home office por parte do mercado corporativo.</p>
              
              <p>A medida é a alternativa mais recomendada para equilibrar com segurança a saúde financeira da empresa e a saúde física de seus colaboradores. Diante desse cenário, a tecnologia e a inovação têm auxiliado as empresas a solucionar problemas gerados nesta crise.</p>
              
              <h3>Como as empresas se adaptaram ao home office</h3>

              <p>Empresas que nunca consideraram o home office como formato de trabalho tiveram que se adaptar às pressas. Isso fez com que os processos seletivos presenciais fossem interrompidos. Mas não quer dizer que as seleções tenham parado de vez. As empresas agora estão realizando vídeo entrevistas com seus candidatos. Além disso, as contratações online cresceram de forma significativa nesse período de pandemia.</p>
              
              <p>De acordo com o Agência Brasil, até o mês de julho de 2020, quase metade das empresas havia entrado no esquema de home office, elevando muito o percentual de brasileiros trabalhando de casa. Além disso, esse movimento causou uma tendência de home office que transpassa a fase de pandemia.</p>

              <p>Uma pesquisa realizada pela empresa de cibersegurança Fortinet mostrou que, após a pandemia, 30% das empresas devem seguir com o home office sendo regime de trabalho, mantendo mais da metade de seus funcionários nesse modelo. Essas empresas planejam aumentar em 90% seus investimentos em estrutura para o teletrabalho, o que resulta em cerca de US $250 milhões</p>

              <p>De acordo com os dados apresentados e com a realidade que estamos vivendo, o trabalho remoto veio para ficar e cada vez mais empresas e pessoas estão em busca desse modelo de trabalho, sendo assim, é relevante saber que o crescimento dessas vagas são reais e estão em alta no mercado.</p>

              <h3>Vantagens para quem opta pelo trabalho remoto</h3>

              <p>Além da pandemia, há outros motivos para buscar vagas no home office, entre as principais vantagens do trabalho remoto para os colaboradores estão a maior flexibilidade do horário, fora o conforto e comodidade de sua casa.</p>

              <p>A economia é outro ponto forte para quem trabalha como home office, pois diminui gastos com transportes, pessoas que  antes gastavam mais de uma hora no transporte público ou no trânsito, por exemplo, podem utilizar esse tempo para outras atividades e Fora gastos em restaurantes.</p>

              <p>Essa economia ajuda tanto o empregador quanto o empregado. Isso fez também o meio corporativo  atingir um maior número de pessoas com eventos online, treinamentos digitais e contratações de vídeo chamadas.</p>

              <h3>Como buscar um emprego home office</h3>

              <p>Na atualidade, e com o novo Coronavírus, os meios de buscar empregos em home office aumentaram. Na plataforma da Catho, por exemplo, você consegue localizar essas oportunidades digitando palavras-chaves que indiquem que a vaga seja home office.</p>

              <p><strong>Por exemplo</strong>: home office, trabalho remoto, trabalho à distância, modelo híbrido, teletrabalho.</p>

              <p>Para conquistar essa vaga, o processo também se torna remoto. As entrevistas se tornam online, assim como todo o processo seletivo. Para ir bem no processo à distância, estar bem preparado é fundamental. Sendo assim preparamos alguns exemplos para você: </p>

              <p>1. <strong>Sempre teste a internet antes de começar a entrevista</strong>, assim evita imprevistos acontecerem, e atrasos também. Ligar a câmera e uma boa iluminação é importante também para que a entrevista remota se assemelhe o máximo com a presencial.</p>
              
              <p>2. <strong>Procure sempre um lugar silencioso também</strong>, para evitar ruídos durante a sua entrevista e principalmente distrações. Assim, além de mostrar profissionalismo, mostra também o quão pronto você está no trabalho remoto <br /> Conheça os nossos <a href="https://www.catho.com.br/carreira-sucesso/category/modelo-curriculo/" target="_blank"><strong>Modelos de Currículo</strong></a></p>

              <p>O home office trouxe grande impacto para o meio corporativo, sendo assim uma estratégia que ficará no mercado de trabalho atual até mesmo quando o isolamento social não for uma realidade para todos.</p>

              <p>A busca por esse modelo de trabalho não é só feita pelos recrutadores, mas também pelos candidatos que estão dispostos a contribuírem a distância,  pois abre uma enorme oportunidade de pessoas de outras localidades trabalharem em grandes empresas.</p>

              
              `,
              iframeUrl: require("../assets/post/audio-2/bg-audio-2.png"),
              info: {
                views: "200",
                likes: "20",
                comments: "2",
              },
              speaker: [
                {
                  name: "Maria Eduarda",
                  avatarImg: require("../assets/post/audio-2/autor1-audio-2.jpg"),
                  jobTitle: "Jovem aprendiz no setor de Marketing Mídia",
                  type: "Sobre a autora", // palestrante, autor, escritor
                  description:
                    "Jovem aprendiz no setor de Marketing Mídia pela empresa Catho, desenvolvendo conteúdo e atendimento nas redes sociais.  Trabalhou em outra organização na área de design e atendimento. Motivada a crescer profissionalmente  e ganhar conhecimento. ",
                },
                {
                  name: "Débora Alves",
                  avatarImg: require("../assets/post/audio-2/autor2-audio-2.jpg"),
                  jobTitle: "Jovem aprendiz no setor de Marketing Mídia",
                  type: "Sobre a autora", // palestrante, autor, escritor
                  description:
                    "Formada técnica em Multimídia, atualmente Jovem Aprendiz na Catho na área de Marketing Mídia, criando conteúdo e atendimento para as redes sociais. Determinação e Metas a faz ser uma profissional empenhada e motivada a realizar os trabalhos propostos.",
                },
              ],
              relatableContent: [],
              seeMore: [
                // Palestra 02
                {
                  id: "01",
                  slug: "vagas-em-tecnologia-dicas-de-um-head-hunter-para-conseguir-a-sua",
                  bgImg: require("../assets/post/palestra-2/palestra-02.png"),
                  type: "palestra",
                  datetime: "2021-05-10 17:00:00",
                  shortDescription:
                    "Vagas em tecnologia: dicas de um head hunter para conseguir a sua!",
                },
                // Artigo 02
                {
                  id: "02",
                  slug: "fique-na-mira-de-um-headhunter",
                  bgImg: require("../assets/post/artigo-2/artigo-02.jpg"),
                  type: "artigo",
                  datetime: "2022-05-09 17:00:00",
                  shortDescription:
                    "Fique na mira de um headhunter!",
                },
              ],
            }, */
            // Artigo 02
            {
              id: "06",
              bgImg: require("../assets/post/artigo-2/artigo-02.jpg"),
              slug: "fique-na-mira-de-um-headhunter", // unico
              type: "artigo",
              typeDisplay: "artigo",
              datetime: "2022-05-09 18:00:00",
              duration: "30",
              week: 2,
              shortDescription:
                "Fique na mira de um headhunter!",
              description: `
                <h2>Fique na mira de um headhunter!</h2>
                <p>Você sabia que há um profissional que atua como detetive de talentos? Sim! São os famosos hedhunter.</p>
                <p>Descubra agora as funções desse profissional, como eles contribuem para a sua entrada no mercado de trabalho e muito mais.</p>

                <p><strong>Quem é o hedhunter?</strong><br>O headhunter é um profissional que tem como papel "caçar" os melhores talentos ativos no mercado, visando sempre a demanda das empresas.</p>
                <p>O papel principal para quem está neste cargo é de ser responsável por identificar esses profissionais qualificados. Eles auxiliam realizando entrevistas e tentando encontrar o grupo ou o candidato ideal para aquela empresa que o contratou.</p>
                <p>Normalmente, os headhunters não são contratados por candidatos e sim por empresas, e também não tem contrato fixo com apenas uma companhia só. Por isso, fique atento ao seu network, pois um "caçador de talentos" pode estar a sua procura!</p>
                <p>Há no mercado também um possível ajudante para esse profissional: os consultores de outplacement. Eles podem ser contratados tanto por empresas que efetuaram certas demissões ou até por candidatos que tem a esperança de trabalhar naquela empresa dos sonhos.</p>
                <p><strong>Como estar na mira do hedhunter?</strong><br>Bom, se você está em busca de oportunidades, estar ao alcance dos caçadores de talentos irá facilitar a sua entrada no mercado de trabalho.</p>
                <p>Veja algumas dicas para se destacar e ser notado por um hedhunter:</p>
                <p><strong>1. Apareça para o mercado</strong><br>O headhunter, geralmente, faz suas procuras por alguém que está fora da sua roda de conhecidos. Por isso, o ideal é você estar atento aos locais onde o mercado profissional pode estar presente, assim como as redes sociais, eventos e palestras.</p>
                <p><strong>2. Cadastre-se em sites de emprego</strong><br>Navegue em sites de emprego que são confiáveis, como a Catho, onde v0cê pode realizar o cadastro do seu currículo e fazer um "match" com recrutadores e com quem está a busca de novos talentos. </p>
                <p><strong>3. Mantenha-se ativo</strong><br>Não esqueça de ter uma rotina de candidatura e atualizações, assim você irá mostrar que está ativo e a procura de oportunidades.</p>
                <p>Além disso, mantenha seus dados em dias, pois os headhunters tendem a fazer contatos via telefone, e-mails e até mesmo em seus perfis nas redes.</p>
                <p>E aí, curtiu saber mais sobre os caçadores de talentos? Aproveite e compartilhe o link desse texto lá nas suas redes sociais!</p>

              `,
              iframeUrl: require("../assets/post/artigo-2/artigo-02-interna.png"),
              info: {
                views: "200",
                likes: "20",
                comments: "2",
              },
              speaker: [
                {
                  name: "Fernanda Zoubaref",
                  avatarImg: require("../assets/post/artigo-2/autor-artigo-2.jpg"),
                  // jobTitle: "Analista de Conteúdo Pleno",
                  type: "Sobre a autora", // palestrante, autor, escritor
                  description:
                    "Jornalista, formada também em Comunicação Visual, está na Catho há mais de um ano e começou suas atividades na empresa como redatora do Blog da Catho Educação. Desde então, cria conteúdos para as redes sociais, blog, atendimento e auxilia em processos de campanhas. <br><br>Em seu passado profissional, já produziu um programa para TV, trabalhou com acessibilidade, foi fotógrafa e hoje se dedica aos aprendizados da área de Marketing. ",
                },
              ],
              relatableContent: [],
              seeMore: [
                // Palestra 02
                {
                  id: "01",
                  slug: "vagas-em-tecnologia-dicas-de-um-head-hunter-para-conseguir-a-sua",
                  bgImg: require("../assets/post/palestra-2/palestra-02.png"),
                  type: "palestra",
                  datetime: "2022-05-09 18:00:00",
                  shortDescription:
                    "Vagas em tecnologia: dicas de um head hunter para conseguir a sua!",
                },
                // Áudio 02
                // {
                //   id: "02",
                //   slug: "como-aproveitar-o-momento-de-alta-e-buscar-sua-vaga",
                //   bgImg: require("../assets/post/audio-2/audio-02.png"),
                //   type: "áudio",
                //   datetime: "2021-05-07 17:00:00",
                //   shortDescription:
                //     "Como aproveitar o momento de alta e buscar sua vaga.",
                // },
              ],
            },
          ],
        },
        {
          week: 3,
          events: [
            // Palestra 03
            {
              id: "07",
              bgImg: require("../assets/post/palestra-3/palestra-03.png"),
              slug: "saude-emocional-no-trabalho-aprenda-como-cuidar-e-manter", // unico
              type: "palestra",
              typeDisplay: "palestra",
              datetime: "2022-05-16 18:00:00", // para a página ser liberada
              livetime: "2022-05-16 18:00:00", // para exibição da barra de live
              duration: "60",
              week: 3,
              shortDescription:
                "Saúde emocional no trabalho. Aprenda como cuidar e manter.",
              description: ``,
              iframeUrl: "https://www.youtube.com/embed/q6lEFqhRDw0",
              info: {
                views: "200",
                likes: "20",
                comments: "2",
              },
              speaker: [
                {
                  name: "Andrea Ladislau",
                  avatarImg: require("../assets/post/palestra-3/autor1-palestra-3.jpg"),
                  jobTitle: "Dra. em Psicanálise",
                  type: "Sobre os palestrantes", // palestrante, autor, escritor
                  description:
                    "Psicanalista (SPM); Doutora em Psicanálise, membro da Academia Fluminense de Letras –cadeira de número 15 de Ciências Sociais; administradora hospitalar e gestão em saúde (AIEC/Estácio); pós-graduada em Psicopedagogia e Inclusão Social (Facei); professora na graduação em Psicanálise; embaixadora e diplomata In The World Academy of Human Sciences US Ambassador In Niterói; membro do Conselho de Comissão de Ética e Acompanhamento Profissional do Instituto Miesperanza; professora associada no Instituto Universitário de Pesquisa em Psicanálise da Universidade Católica de Sanctae Mariae do Congo; professora associada do Departamento de Psicanálise du Saint Peter and Saint Paul Lutheran Institute au Canada, situado em souhaites; graduada em Letras - Português e Inglês pela PUC de Belo Horizonte.",
                },
                {
                  name: "Rodrigo Silva",
                  avatarImg: require("../assets/post/palestra-3/autor2-palestra-3.jpg"),
                  jobTitle:
                    "Co-Fundador do canal BlackPeople e Diretor da Brave.ag",
                  type: "Sobre os palestrantes", // palestrante, autor, escritor
                  description:
                    "Mochileiro, Empresário, Palestrante, Podcaster, Gestor de Negócios Digitais há 16 anos, Formado em Marketing e Especialista em Marketing Digital, com 20 leões em Cannes, além de projetos premiados pela LIA (Londres), Clio Awards (NYC) e El Ojo de Iberoamérica.",
                },
                {
                  name: "Christiane Araújo",
                  avatarImg: require("../assets/post/palestra-3/autor3-palestra-3.jpg"),
                  jobTitle: "Gerente Sr. do Jurídico e Compliance Catho",
                  type: "Sobre os palestrantes", // palestrante, autor, escritor
                  description:
                    "Formada em Direito pela Universidade Cândido Mendes (1997), no Rio de Janeiro, tenho mais de 15 anos de experiência nas áreas de Direito Empresarial e Compliance, tendo atuado como advogada interna de empresas dos ramos da aviação e logística, comércio eletrônico, meios de pagamento e, atualmente, como Gerente Jurídica e Compliance Sênior da Catho, empresa de tecnologia que possibilita a aproximação entre candidatos e recrutadores.",
                },
              ],
              relatableContent: [],
              seeMore: [
                // Áudio 03
                // {
                //   id: "02",
                //   slug: "o-que-as-mulheres-da-tecnologia-devem-buscar-nas-empresas",
                //   bgImg: require("../assets/post/audio-3/audio-03.png"),
                //   type: "áudio",
                //   datetime: "2022-05-16 17:00:00",
                //   shortDescription:
                //     "O que as mulheres da tecnologia devem buscar nas empresas?",
                // },
                // Artigo 03
                {
                  id: "01",
                  slug: "como-a-empresa-que-voce-trabalha-investe-em-sua-saude",
                  bgImg: require("../assets/post/artigo-3/artigo-03.jpg"),
                  type: "artigo",
                  datetime: "2022-05-16 18:00:00",
                  shortDescription:
                    "Como a empresa que você trabalha investe em sua saúde",
                },
              ],
            },
            // Audio 03
            /* {
              id: "08",
              bgImg: require("../assets/post/audio-3/audio-03.png"),
              slug: "o-que-as-mulheres-da-tecnologia-devem-buscar-nas-empresas", // unico
              type: "podcast",
              typeDisplay: "áudio",
              datetime: "2022-05-16 12:00:00",
              duration: "30",
              week: 3,
              shortDescription:
                "O que as mulheres da tecnologia devem buscar nas empresas?",
              description: `
                <audio controls>
                  <source src="${require("../assets/post/audio-3/audio-3.mp3")}" type="audio/mp3">
                  Your browser does not support the audio element.
                </audio>
                <p>Numa área de tecnologia que é predominantemente masculina, as mulheres que buscam se posicionar neste mercado enfrentam diversos e grandes desafios, a começar pela falta de estímulo durante a infância e a fase escolar, a pouca representatividade feminina no setor e o machismo que acaba por afastá-las das áreas tecnológicas.</p>
                <p>O machismo, em especial, é o mecanismo que mais dificulta a inserção feminina na área tech.</p>
                <p>As salas de aulas dos cursos de ciências da computação, por exemplo, podem ser ambientes potencialmente prejudiciais emocionalmente para elas. Não faltam relatos de mulheres que foram discriminadas por professores e colegas, que duvidaram de suas capacidades de aprendizado e de performance simplesmente por causa do gênero, contribuindo muito com a desistência de inúmeras graduandas. Sinal disso é que apenas 13% dos alunos matriculados neste curso, de acordo com uma pesquisa do IME-USP, são mulheres.</p>
                <p>Outros dados alarmantes que indicam a desigualdade de gênero neste segmento foram levantados pela Catho. De acordo com a Pesquisa Salarial Catho 2021, apenas 19% dos profissionais de TI são mulheres e elas ganham até 11% a menos que os homens. Olhando para a área de programação, apenas 14% são mulheres.</p>
                <p>E considerando tudo isso, concluímos que… <strong>O futuro está sendo programado por homens.</strong></p>
                <p>As mulheres estão sendo excluídas do mercado de tecnologia no Brasil. Enquanto elas representam menos de ¼, os homens dominam a participação, chegando a quase 80%. E se nos atentarmos ao fato que o futuro já é e cada vez será mais digital e tecnológico, percebemos que o que devemos esperar, lá na frente, é mais machismo. Se a grande maioria dos desenvolvimentos de equipamentos, softwares, produtos, soluções e tudo mais, são os homens que realizam, porque a tecnologia seria inclusiva para todas as pessoas? Como poderíamos ter soluções tecnológicas pensadas para mulheres?</p>
                <p>Além disso tudo, a conscientização a respeito da pauta ainda é muito recente. Apesar do forte esforço criado por coletivos de mulheres da área, empresas que nasceram com o propósito de equilibrar a balança e empresas que buscaram diversificar seus times, falta muito para o debate transcender em práticas consistentes. Afinal, dentre as empresas que não estão engajadas com as causas de gênero, que infelizmente ainda são muitas, o assunto não é nem mesmo discutido. Quantos desses executivos acreditam que as mulheres deveriam mesmo ter oportunidades iguais às deles? Quantos ainda acham que elas devem tomar conta de casa e que eles precisam ser os provedores financeiros? Quantos CEOs e líderes que são homens desejam abrir mão do seu privilégio e serem liderados por mulheres?</p>
                <p>Sabendo disso, as mulheres da área precisam estar atentas na hora de escolher uma oportunidade de trabalho, quando for possível fazer uma escolha.</p>
                <h3>O que as mulheres de tecnologia devem buscar numa empresa?</h3>
                <p>Então, mais do que uma vaga aberta em algum dos setores de TI e Devel da empresa, elas precisam buscar outras características nas companhias para poderem ter uma experiência plena, aplicar seus conhecimentos com segurança, aprender e crescer profissionalmente.</p>
                <h4>1- Representatividade e proporcionalidade:</h4>
                <p>Chegar em uma empresa como a nova funcionária de tecnologia e não ser a única mulher da área já é um bom sinal e um alívio. É muito importante que as mulheres estejam representadas na área, mas também é fundamental olhar para a proporcionalidade. Além de você, mais uma ou duas mulheres, existem outras? Por quem são ocupados os cargos de liderança? Existem mulheres nas cadeiras que dirigem a empresa? As outras áreas também são abertas para as mulheres? Qual a proporção de mulheres em cada setor, na tecnologia e nas lideranças?</p>
                <p>A desproporção pode apontar uma falsa prática de equidade pautada no pilar de gênero que, no fim, não inclui de fato as mulheres no mercado de trabalho nem acolhe suas ideias e identidades verdadeiramente.</p>
                <h4>2- Diversidade por completo:</h4>
                <p>A busca por maior participação feminina na TI não se limita a conquistar cargos. É preciso conquistar voz e escuta e não se contentar com meias justiças. O mercado de trabalho é excludente com os mais variados grupos sociais, que são menosprezados durante os processos educacionais, de recrutamento pelas empresas e também nos relacionamentos dentro dos ambientes de trabalho. E se a empresa que você está cogitando ir trabalhar “SÓ” se importa com as mulheres em tecnologia, desconfie. Diversidade, como o próprio nome já diz, é diversa. Procure empresas que queiram também incluir pessoas negras, pessoas com deficiência, mães, lésbicas, gays, bissexuais, transgêneros, idosos, refugiados e todas as pessoas em seus quadros de funcionários e em todos os níveis. Isso é um importante indicador do quanto você terá de voz e liberdade.</p>
                <h4>3- Salários Iguais:</h4>
                <p>A diferença salarial apontada pela Catho entre homens e mulheres em tecnologia é de, em média, 11%. Isso significa que temos um mesmo peso com duas medidas. Mulheres que realizam os mesmos serviços que os homens e recebem menos por isso. Não dá, né? Se possível, busque por empresas que tenham uma política mais transparente quanto aos salários. Boa parte das empresas complica o processo de negociação salarial, questionando quanto as mulheres ganham atualmente, qual foi o último salário ou quanto desejam ganhar, ao invés de falar logo: “pago tanto”. E isso também pode ser uma forma de tentar pescar o que tem por trás dessa negociação. O recrutador está tentando pagar o mínimo aceitável pela candidata ou o quanto ele realmente dispõe como teto para o profissional ideal para aquela vaga em questão?</p>
                <h4>4- Reconhecimento: financeiros e mimos</h4>
                <p>Ser reconhecida não é ter promoção após anos e anos dando o suor sem receber outras formas de reconhecimento. Faz parte também receber agradecimentos, feedbacks e elogios públicos. E aumentos e promoções. O verdadeiro reconhecimento é ter o trabalho valorizado sempre, sem ter as habilidades postas em xeque. É ter o nome como uma das referências da equipe naquilo que se faz. É ser lembrada quando vai rolar alguma ação especial e divertida, quando vai ter presentes pros colaboradores que mais se destacaram no período. É conquistar altos cargos e salários.</p>
                <p>Só o dinheiro não resolve. Só elogios não resolvem. Reconhecimento profissional tem que ser por completo.</p>
                <p><strong>Quer saber mais?</strong></p>
                <h4>Ouça um podcast sobre mulheres na tecnologia!</h4>
                <p><a href="https://www.trampapo.com.br/episodes/mulheres-na-tecnologia" rel="nofollow">O episódio 20 do Trampapo</a>, podcast produzido pela Cartho e apresentado pela Ana Paula Xongani, trouxe convidadas do UX para Minas Pretas e do coletivo Wo Makers Code para conversar sobre esse tema. Pesquise pelo Trampapo e escute!</p>
                `,
              iframeUrl: require("../assets/post/audio-3/audio-03.png"),
              info: {
                views: "200",
                likes: "20",
                comments: "2",
              },
              speaker: [
                {
                  name: "Jacqueline Carmo",
                  avatarImg: require("../assets/post/audio-3/autor-audio-3.png"),
                  jobTitle: "Analista de Conteúdo Pleno",
                  type: "Sobre a autora", // palestrante, autor, escritor
                  description: `
                    <p>Há mais de 5 anos é responsável por idealizar e produzir conteúdos para a Catho focando na informação dos profissionais, no mercado de trabalho, na educação e na construção de ambientes mais diversos dentro do corporativismo.</p>
                    <p>É formada em marketing e tem diversas qualificações em mídias sociais, comunicação e conteúdo. Na empresa, responde pela direção criativa do Podcast Trampapo, gestão das redes sociais, pela comunicação publicitária e curadoria de campanhas de conteúdo.</p>
                  `,
                },
              ],
              relatableContent: [],
              seeMore: [
                // Palestra 03
                {
                  id: "01",
                  slug: "saude-emocional-no-trabalho-aprenda-como-cuidar-e-manter",
                  bgImg: require("../assets/post/palestra-3/palestra-03.jpg"),
                  type: "palestra",
                  datetime: "2022-05-16 17:00:00",
                  shortDescription:
                    "Saúde emocional no trabalho. Aprenda como cuidar e manter.",
                },
                // Artigo 03
                {
                  id: "02",
                  slug: "como-a-empresa-que-voce-trabalha-investe-em-sua-saude",
                  bgImg: require("../assets/post/artigo-3/artigo-03.jpg"),
                  type: "artigo",
                  datetime: "2022-05-16 17:00:00",
                  shortDescription:
                    "Como a empresa que você trabalha investe em sua saúde",
                },
              ],
            }, */
            // Artigo 03
            {
              id: "09",
              bgImg: require("../assets/post/artigo-3/artigo-03.jpg"),
              slug: "como-a-empresa-que-voce-trabalha-investe-em-sua-saude", // unico
              type: "artigo",
              typeDisplay: "artigo",
              datetime: "2022-05-16 18:00:00",
              duration: "30",
              week: 3,
              shortDescription:
                "Como a empresa que você trabalha investe em sua saúde",
              description: `
                <p>Será que o plano de saúde oferecido pelas empresas é o único modo de preservar a saúde dos colaboradores? Veja mais detalhes de como as companhias podem melhorar a qualidade de vida de seus profissionais e compare com o seu local de trabalho.</p>

                <p><strong>Como a empresa que você trabalha investe em sua saúde</strong></p>
                <p>Oferecer planos de saúde para funcionários é uma alternativa de cuidado que muitas empresas adotam. Apesar disso, existem outras formas mais efetivas de investimento no bem-estar dos colaboradores que podem, além de ser benéficas, ajudar no rendimento profissional da empresa. Investir, portanto, em diferentes formas de cuidado é essencial para ambas as partes.</p>
                <p>De acordo com a gestora de carreira e especialista em RH, Madalena Feliciano, "muitas empresas acreditam que oferecer mais benefícios aos funcionários além do plano de saúde é um gasto desnecessário. No entanto, não é bem assim". Isso porque, os custos de não dar atenção à saúde dos colaboradores são muito mais altos, haja vista os dias em casa necessários e os encargos a mais que isso pode gerar.</p>
                <p>Além disso, vale ressaltar também que colaboradores que estão em um alto nível de bem-estar geralmente produzem mais. Desse modo, mais engajados e comprometidos, sendo assim, empresas que investem na saúde dos funcionários tendem a ter um negócio bem-sucedido.</p>
                <p>Com isso em vista, prevenir e oferecer meios de permanecer bem com a sua saúde  é muito importante. "Mais do que o plano, existem outras formas de se preocupar com o bem-estar dos funcionários", afirma Madalena.</p>
                <p><strong>Meios de você investir em seu bem-estar</strong></p>
                <p>Confira abaixo alguns meios de você investir no seu bem-estar, unindo trabalho e vida pessoal.</p>
                <ol>
                    <li>
                        <p><strong>Home office e horário flexível:</strong> Para aqueles que utilizam o formato de trabalho remoto, garantir a flexibilidade de horário é muito importante. Isso porque permite que você trabalhe em um horário melhor de acordo com a sua realidade.</p><br>
                    </li>
                </ol>
                <ol start="2">
                    <li>
                        <p><strong>Praticar atividades física:</strong> Mesmo se em sua empresa não tiver um espaço físico, existem benefícios que podem também oferecer meios de garantir a prática de bem-estar físico e mental. Recomende ao RH de onde você trabalha parcerias com academias e afins.</p><br>
                    </li>
                </ol>
                <ol start="3">
                    <li>
                        <p><strong>Tenha refeições e lanches saudáveis à vontade:</strong> A alimentação é uma das melhores formas de se garantir uma boa saúde, por isso, investir em alimentos saudáveis pode gerar resultados incríveis para sua saúde. "Proporcionar refeições saudáveis é muito importante para a saúde dos funcionários", alerta Feliciano as empresas que se preocupam com a saúde de seus funcionários.</p>
                    </li>
                    <li>
                        <p><strong>Busque apoio psicológico:</strong> Entenda que cuidar da saúde estende-se também aos cuidados com a saúde mental, oferecer formas de lidar com o estresse e ansiedade é uma ótima forma de promover um espaço de bem-estar. Você pode procurar por colegas de sua empresa que são psicólogos ou até mesmo utilizar fontes gratuitas para bater um papo sempre que precisar.</p><br>
                    </li>
                </ol>
                <p>Além dessas, é importante sempre buscar formas de tratamento de acordo com a sua realidade. Sempre que necessário, busque ajuda e não se cale. Estar saudável, tanto psicologicamente e físico, é algo crucial para a sua carreira.</p>
                <p>E aí, sua empresa está investindo em sua saúde? Comente lá nas redes sociais sobre o assunto e aproveite para compartilhar o link desse texto!</p>
              `,
              iframeUrl: require("../assets/post/artigo-3/artigo-03-interna.png"),
              info: {
                views: "200",
                likes: "20",
                comments: "2",
              },
              speaker: [
                {
                  name: "Maria Eduarda Goncalves Pereira",
                  avatarImg: require("../assets/post/artigo-3/autor1-artigo-3.jpg"),
                  /* jobTitle: "Jovem aprendiz no setor de Marketing Mídia", */
                  type: "Sobre a autora", // palestrante, autor, escritor
                  description:
                    "Jovem aprendiz no setor de Marketing Mídia pela empresa Catho, desenvolvendo conteúdos para o blog da empresa. Trabalhou em outra organização na área de design e atendimento. Motivada a crescer profissionalmente e ganhar conhecimento.",
                },
              ],
              relatableContent: [],
              seeMore: [
                // Palestra 03
                {
                  id: "01",
                  slug: "saude-emocional-no-trabalho-aprenda-como-cuidar-e-manter",
                  bgImg: require("../assets/post/palestra-3/palestra-03.png"),
                  type: "palestra",
                  datetime: "2022-05-16 18:00:00",
                  shortDescription:
                    "Saúde emocional no trabalho. Aprenda como cuidar e manter.",
                },
                // Áudio 03
                // {
                //   id: "02",
                //   slug: "o-que-as-mulheres-da-tecnologia-devem-buscar-nas-empresas",
                //   bgImg: require("../assets/post/audio-3/audio-03.png"),
                //   type: "áudio",
                //   datetime: "2022-05-16 17:00:00",
                //   shortDescription:
                //     "O que as mulheres da tecnologia devem buscar nas empresas?",
                // },
              ],
            },
          ],
        },
        {
          week: 4,
          events: [
            // Palestra 04
            {
              id: "10",
              bgImg: require("../assets/post/palestra-4/palestra-04.png"),
              slug: "do-curriculo-a-entrevista-passo-a-passo-para-conseguir-sua-vaga", // unico
              type: "palestra",
              typeDisplay: "palestra",
              datetime: "2022-05-23 18:00:00", // datetime
              livetime: "2022-05-23 18:00:00",
              duration: "60",
              week: 4,
              shortDescription:
                "Do currículo à entrevista! Passo a passo para conseguir sua vaga.",
              description: ``,
              iframeUrl: "https://www.youtube.com/embed/Ql4XjH3v0KA",
              info: {
                views: "200",
                likes: "20",
                comments: "2",
              },
              speaker: [
                {
                  name: "Patrícia Suzuki",
                  avatarImg: require("../assets/post/palestra-4/autor-palestra-4.png"),
                  jobTitle: "Diretora de Gente & Gestão Catho",
                  type: "Sobre os palestrantes", // palestrante, podcaster, escritor
                  description:
                    "Patricia tem formação em administração de empresas, possui mais de 20 anos de experiência em Recursos Humanos, com atuação na Europa e América Latina. Responsável pela gestão de equipes com reportes diretos e funcionais, dispersas geograficamente.",
                },
                {
                  name: "Gisele Silva",
                  avatarImg: require("../assets/post/palestra-4/autor2-palestra-4.png"),
                  jobTitle:
                    "Líder de Recrutamento e Seleção Bayer",
                  type: "palestrante", // palestrante, podcaster, escritor
                  description:
                    "Gisele é formada em Psicologia com 14 anos de experiência na área de Recursos Humanos, possui atuação generalista nos segmentos de TI, Aviação, Finanças , Farmacêutico e Product Supply. Ingressou na Bayer em 2019 no time de Talent Acquisition onde vem desempenhando o papel de líder de recrutamento para as divisões Farmacêutica, consumer health e áreas corporativas. Como psicóloga é apaixonada pela mente humana e toda a sua diversidade. O bom humor é uma forte característica sua e em seu tempo livre adora fazer bolos, doces e sobremesas, um hobby que já foi até profissão.",
                },
              ],
              relatableContent: [],
              seeMore: [
                // Áudio 04
                // {
                //   id: "02",
                //   slug: "ferramentas-que-voce-precisa-conhecer-para-se-destacar-no-trabalho",
                //   bgImg: require("../assets/post/audio-4/audio-04.png"),
                //   type: "áudio",
                //   datetime: "2021-05-21 12:00:00",
                //   shortDescription:
                //     "Ferramentas que você precisa conhecer para se destacar no trabalho",
                // },
                // Artigo 04
                {
                  id: "01",
                  slug: "e-novo-no-mercado-veja-o-passo-a-passo-para-conseguir-o-seu-emprego",
                  bgImg: require("../assets/post/artigo-4/artigo-04.jpg"),
                  type: "artigo",
                  datetime: "2022-05-23 18:00:00",
                  shortDescription:
                    "É novo no mercado? Veja o passo a passo para conseguir o seu emprego.",
                },
              ],
            },
            // Audio 4
            /* {
              id: "11",
              bgImg: require("../assets/post/audio-4/audio-04.png"),
              slug: "ferramentas-que-voce-precisa-conhecer-para-se-destacar-no-trabalho", // unico
              type: "podcast",
              typeDisplay: "áudio",
              datetime: "2021-05-21 12:00:00", // datetime
              duration: "30",
              week: 4,
              shortDescription:
                "Ferramentas que você precisa conhecer para se destacar no trabalho",
              description: `
              <audio controls>
                <source src="${require("../assets/post/audio-4/audio-4.mp3")}" type="audio/mp3">
                Your browser does not support the audio element.
              </audio>

              <p>A <a href="https://www.catho.com.br/profissoes/buscar/tecnologia" target="_blank"><strong>tecnologia</strong></a> está presente em todas as áreas, auxiliando nas tarefas e deixando o mercado mais rápido e dinâmico. E algumas dessas ferramentas podem ser vistas como novidades ou até mesmo como barreira para ingressar em uma carreira profissional ou daquela tão sonhada promoção chegar.</p>
              <p>Com o intuito de revelar ferramentas online e até gratuitas, destacamos agora algumas que possam dar um brilho a mais em seu currículo ao dizer que as conhecem, mesmo que não tenham muito a ver com a sua área de atuação ou de interesse. São plataformas atuais e utilizadas por grandes empresas. Descubra:</p>
              <h4>1. Sites de programação</h4>
              <p>Podemos ainda ter uma visão que a <a href="https://www.catho.com.br/profissoes/buscar/programador" target="_blank"><strong>programação</strong></a> é apenas específica para algumas áreas, mas não é bem assim. Ela se tornou algo essencial no mercado e quem sabe dominá-la está no lucro.</p>
              <p>Além dos cursos e formações voltadas para programadores, atualmente, existem sites e softwares gratuitos que são fáceis e aptos até mesmo para pessoas que não entendem muito. Hoje, as empresas estão buscando <a href="https://www.catho.com.br/profissoes/buscar/programador" target="_blank"><strong>programação</strong></a> para sites, para aplicativos e para <a href="https://www.catho.com.br/profissoes/buscar/business-intelligence" target="_blank"><strong>business intelligence</strong></a>.</p>
              <h4>2. Diferentes certificados online</h4>
              <p>A qualificação não é algo novo e nem novidade para quem deseja se destacar perante o concorrido mercado de trabalho. Mas o que pode ser desconhecido são os cursos online e os certificados que valem por alguns anos.</p>
              <p>Com o objetivo de ser mais prático, barato e rápido, as aulas virtuais viraram uma febre nesse momento em que necessitamos ficar em casa. Especialistas também comentam que essas plataformas só estão começando a crescer, que estudar sem ir a uma sala de aula é tendência e bem vista pelas as empresas, já que algumas investem e liberam a grade para os funcionários estudarem. </p>
              <p>Então, se você está pensando em atualizar seus conhecimentos e se tornar um grande competidor, provavelmente esse estilo de qualificação ajudará e muito.</p>
              <h4>3. Sites para criação de imagens e apresentações</h4>
              <p>Já se foi o tempo em que saber diagramar, escolher boas imagens e criar apresentações assertivas era coisa de uma área só. Nessa nova década, um resultado ou um projeto bem elaborado e ilustrado vale uma promoção, sabia?!  </p>
              <p>E a importância de utilizar a imagem como ferramenta de trabalho para diversos segmentos se tornou tão útil que até podemos encontrar na internet sites gratuitos que ajudam o profissional a criar slides incríveis, a modificar imagens, a montar vídeos, gifs e elaborar o visual de uma página de site. É só pesquisar na internet “edição de imagem/vídeo/gif” que você logo vai encontrar o que precisa.</p>
              <h4>4. Idioma – apps, vídeos e podcasts</h4>
              <p>Para encerrar, não podíamos deixar de falar de algo que se tornou até clichê, mas importante: saber um outro idioma. Sabemos que ser bilíngue pode fazer com que grandes portas se abram, mas a realidade é que essa teoria vale para todas as áreas e idades. </p>
              <p>Além de tornar o intercâmbio ou a moradia em um outro país possível, saber ler e escrever em outra língua serve para entender ferramentas que ainda não foram atualizadas para o nosso idioma. Ah! E se engana quem acredita que esperar por isso pode ser uma boa, pois as oportunidades são momentâneas e as traduções tardias.</p>
              <p>E o mais bacana é que hoje existem videoaulas, conteúdos bons para baixar e muita coisa de forma gratuita para o candidato aprender um novo idioma com facilidade e rapidez. Você pode pesquisar na internet aplicativos, podcasts, canais e criadores de conteúdo que ensinam idiomas. Principalmente para o inglês, não faltam opções para todos os gostos.</p>
              <h4>Dicas de como ingressar no mercado de trabalho</h4>
              <p>As oportunidades estão crescendo e se adaptando, assim como o surgimento de novas profissões e novos negócios no país. E com isso, o mercado de trabalho pode se tornar algo mais atrativo para aqueles que desejam ingressar em algo novo ou para aqueles que buscam o primeiro emprego, mesmo com uma idade avançada.</p>
              <p>Conheça então algumas dicas que separamos para você aumentar suas chances em um processo seletivo, independente de área, separadas por experiência ou sem nenhuma vivência. Confira!</p>
              <p>| Conheça os nossos <a href="https://www.catho.com.br/carreira-sucesso/category/modelo-curriculo/" target="_blank"><strong>Modelos de Currículo</strong></a></p>
              <h4>Para pessoas com experiência</h4>
              <p>Se você já trabalhou ou trabalha e está em busca de começar com o pé direito, sigas essas nossas dicas:</p>
              <ul style="list-style: disc; margin-bottom: 15px">
                  <li style="margin-bottom: 10px; line-height: 24px">Em seu currículo, mencione a sua longa experiência, mas faça de uma maneira objetiva e curta</li>
                  <li style="margin-bottom: 10px; line-height: 24px">Se está pensando em mudar de área, pesquise sobre a rotina dos profissionais e as tendências do mercado  </li>
                  <li style="margin-bottom: 10px; line-height: 24px">Crie seu documento com no máximo 2 páginas</li>
                  <li style="margin-bottom: 10px; line-height: 24px">Tenha uma cópia do currículo de forma impressa e virtual</li>
                  <li style="margin-bottom: 10px; line-height: 24px">Busque vagas com o auxílio das palavras-chave</li>
                  <li style="margin-bottom: 10px; line-height: 24px">Siga as empresas do seu interesse nas redes sociais</li>
              </ul>
              <h4 style="margin-top: 60px">Para pessoas sem experiência</h4>
              <p>Agora, se você está iniciando no mercado, então, acompanhe essas nossas dicas:</p>
              <ul style="list-style: disc; margin-bottom: 15px">
                  <li style="margin-bottom: 10px; line-height: 24px">Crie um currículo objetivo e mostrando todos os seus aprendizados ao longo dos anos que possam contribuir para a sua carreira</li>
                  <li style="margin-bottom: 10px; line-height: 24px">Faça um documento com no máximo 1 página</li>
                  <li style="margin-bottom: 10px; line-height: 24px">Tenha também uma cópia do currículo de forma impressa e virtual</li>
                  <li style="margin-bottom: 10px; line-height: 24px">Busque por oportunidades que se encaixam com seu perfil e habilidades</li>
                  <li style="margin-bottom: 10px; line-height: 24px">Demonstre sempre que a falta de experiência não é problema, que você busca por evolução profissional</li>
                  <li style="margin-bottom: 10px; line-height: 24px">Sigas as empresas do seu interesse nas redes sociais</li>
                  <li style="margin-bottom: 10px; line-height: 24px">Procure por vagas utilizando palavras-chaves</li>
              </ul>
              <p>| Descubra mais de 200 mil vagas no <a href="https://www.catho.com.br/lp/acathofunciona/p1?_url=/lp/acathofunciona&" target="_blank"><strong>site da Catho</strong></a></p>
              <p>Agora que você já descobriu as ferramentas que podem te ajudar a ser destaque no mercado e também já conhece as nossas dicas para começar a trabalhar, compartilhe com aqueles seus amigos que também estão buscando por oportunidades. Ah! E aproveite para nos contar o que você acha de novo no mercado atual?</p>`,
              iframeUrl: require("../assets/post/audio-4/bg-audio-4.png"),
              info: {
                views: "200",
                likes: "20",
                comments: "2",
              },
              speaker: [
                {
                  name: "Fernanda Zoubaref",
                  avatarImg: require("../assets/post/audio-4/autor-audio-4.png"),
                  type: "Sobre a autora", // palestrante, autor, escritor
                  description:`
                  Jornalista, formada também em Comunicação Visual, está na Catho há mais de um ano e começou suas atividades na empresa como redatora do Blog da Catho Educação. Desde então, cria conteúdos para as redes sociais, blog, atendimento e auxilia em processos de campanhas.<br />
                  
                  Em seu passado profissional, já produziu um programa para TV, trabalhou com acessibilidade, foi fotógrafa e hoje se dedica aos aprendizados da área de Marketing.
                  `,
                },
              ],
              relatableContent: [],
              seeMore: [
                // Palestra 04
                {
                  id: "01",
                  slug: "do-curriculo-a-entrevista-passo-a-passo-para-conseguir-sua-vaga",
                  bgImg: require("../assets/post/palestra-4/palestra-04.png"),
                  type: "palestra",
                  datetime: "2022-05-23 18:00:00",
                  shortDescription:
                    "Do currículo à entrevista! Passo a passo para conseguir sua vaga.",
                },
                // Artigo 04

                {
                  id: "02",
                  slug: "e-novo-no-mercado-veja-o-passo-a-passo-para-conseguir-o-seu-emprego",
                  bgImg: require("../assets/post/artigo-4/artigo-04.jpg"),
                  type: "artigo",
                  datetime: "2022-05-23 17:00:00",
                  shortDescription:
                    "É novo no mercado? Veja o passo a passo para conseguir o seu emprego.",
                },
              ],
            }, */
            // Artigo 04
            {
              id: "12",
              bgImg: require("../assets/post/artigo-4/artigo-04.jpg"),
              slug: "e-novo-no-mercado-veja-o-passo-a-passo-para-conseguir-o-seu-emprego", // unico
              type: "artigo",
              typeDisplay: "artigo",
              datetime: "2022-05-23 18:00:00", // datetime
              duration: "30",
              week: 4,
              shortDescription:
                "É novo no mercado? Veja o passo a passo para conseguir o seu emprego.",
              description: `
              <h2>É novo no mercado? Veja o passo a passo de como conseguir um bom emprego</h2>

              <p>Você está querendo iniciar uma carreira e não sabe como fazer isso? Bom, seus problemas acabaram! Veja agora como conseguir um bom emprego e conquistar sua independência. </p>
              <p>Saiba os passos necessários para você ter um currículo nota dez e como começar a buscar por oportunidades da maneira correta. </p>
              <p>Acompanhe até o final e boa leitura! </p>
              
              <p><strong>Passos para ingressar no mercado de trabalho</strong></p>
              <p><strong>Passo 01: Fazer o currículo</strong></p>
              <p>O currículo é o documento mais importante em um processo seletivo. É por meio dele que você irá se apresentar para o mercado de trabalho.</p>
              <p>Crie um CV bem caprichado, com todas as informações de sua trajetória profissional, palavras-chave que façam parte da sua área  e qualificações. Atente-se também aos erros gramaticais e não ultrapasse duas páginas.</p>
              <p>Ah! E não se preocupe caso ainda não tenha muito o que inserir, pois o ideal para quem não tem experiência é contar sobre o que você aprendeu na vida que pode ser essencial para aquele cargo desejado.</p>
              <p>Você também pode baixar os nossos modelos de currículo grátis e preenchê-lo com os seus dados!</p>
              <p><strong>Passo 02: Busque vagas de emprego</strong></p>
              <p>Depois de ter um currículo bem bacana para chamar a atenção dos recrutadores, você precisa ir atrás das oportunidades disponíveis no mercado.</p>
              <p>Se você já sabe em quais áreas deseja trabalhar, busque por vagas com o mesmo nome ou também por nomes equivalentes, tipo: auxiliar de serviços gerais e/ou somente serviços gerais.</p>
              <p>Leia atentamente os requisitos descritos no anúncio, veja se combina com o seu perfil profissional e se possível, envie uma mensagem amigável para a empresa, se apresentando de forma objetiva.</p>
              <p>Já nos sites de busca de emprego, como o da Catho, é importante você preencher todos os campos na hora de cadastrar seu currículo, e realizar os testes solicitados ao se candidatar às vagas. Isso ajudará a empresa a encontrar o candidato que mais se adere à vaga.</p>
              <p>Você pode dar um up em suas candidaturas com o nosso Plano Profissional, que além de muitas coisas, faz você subir posições na fila das oportunidades!</p>
              <p><strong>Passo 03: Crie uma rotina</strong></p>
              <p>Agora que você já tem um cartão de visitas bem destacado e já está se candidatando às vagas, é hora de criar uma rotina para não perder o que há de novo no mercado de trabalho.</p>
              <p>A todo momento surgem novas vagas em muitas empresas, por isso, tire um tempo do seu dia para dedicar-se a enviar e-mails e a se candidatar às vagas abertas no site da Catho.</p>
              <p>Baixe gratuitamente o nosso app e tenha em mãos os avisos de vagas para o cargo que deseja. Ele está disponível para Android e IOS.</p>
              <p>Sempre que possível, mantenha seu currículo atualizado, faça uma carta de apresentação e trabalhe em seu perfil profissional nas redes sociais. A internet está recheada de grandes chances para a sua carreira de sucesso.</p>
              <p>E aí, gostou de saber dos passos para conseguir um bom emprego? Então, aproveite e compartilhe o link desse texto com seus amigos!</p>
              `,
              iframeUrl: require("../assets/post/artigo-4/artigo-04-interna.png"),
              info: {
                views: "200",
                likes: "20",
                comments: "2",
              },
              speaker: [
                {
                  name: "Fernanda Zoubaref",
                  avatarImg: require("../assets/post/artigo-4/autor-artigo-4.jpg"),
                  // jobTitle: "Analista de Conteúdo Pleno",
                  type: "Sobre a autora", // palestrante, autor, escritor
                  description:
                    "Jornalista, formada também em Comunicação Visual, está na Catho há mais de um ano e começou suas atividades na empresa como redatora do Blog da Catho Educação. Desde então, cria conteúdos para as redes sociais, blog, atendimento e auxilia em processos de campanhas. <br><br> Em seu passado profissional, já produziu um programa para TV, trabalhou com acessibilidade, foi fotógrafa e hoje se dedica aos aprendizados da área de Marketing.",
                },
              ],
              relatableContent: [],
              seeMore: [
                // Palestra 04
                {
                  id: "01",
                  slug: "do-curriculo-a-entrevista-passo-a-passo-para-conseguir-sua-vaga",
                  bgImg: require("../assets/post/palestra-4/palestra-04.png"),
                  type: "palestra",
                  datetime: "2022-05-23 18:00:00",
                  shortDescription:
                    "Do currículo à entrevista! Passo a passo para conseguir sua vaga.",
                },
                // Áudio 04
                // {
                //   id: "02",
                //   slug: "ferramentas-que-voce-precisa-conhecer-para-se-destacar-no-trabalho",
                //   bgImg: require("../assets/post/audio-4/audio-04.png"),
                //   type: "audio",
                //   datetime: "2021-05-21 12:00:00",
                //   shortDescription:
                //     "Ferramentas que você precisa conhecer para se destacar no trabalho",
                // },
              ],
            },
          ],
        },
        {
          week: 5,
          events: [
            // Palestra 05
            {
              id: "10",
              bgImg: require("../assets/post/palestra-5/palestra-05.png"),
              slug: "conheca-as-pegadinhas-das-entrevistas-de-emprego-e-como-ser-aprovado", // unico
              type: "palestra",
              typeDisplay: "palestra",
              datetime: "2022-05-30 18:00:00", // para a página ser liberada
              livetime: "2022-05-30 18:00:00", // para exibição da barra de live
              duration: "60",
              week: 5,
              shortDescription: "Conheça as “pegadinhas” das entrevistas de emprego e como ser aprovado.",
              description: ``,
              iframeUrl: "https://www.youtube.com/embed/g72tSxOBCNA",
              info: {
                views: "200",
                likes: "20",
                comments: "2",
              },
              speaker: [
                {
                  name: "Clariana Marega",
                  avatarImg: require("../assets/post/palestra-5/autor-palestra-5.jpg"),
                  jobTitle: "Headhunter e fundadora da LiBE",
                  type: "Sobre as palestrantes", // palestrante, podcaster, escritor
                  description: `
                    <p>Psicóloga formada pela USP - SP, atuou em Recursos Humanos de empresas como Walmart, H&R Block e Paychex.</p>
                    <p>Co-fundadora da Amrop Inniti, onde atuou como sócia e headhunter e atualmente faz parte do Conselho.</p>
                    <p>Em 2020 começou a atender profissionais participantes de processos seletivos e em 2021 fundou LiBE, com o propósito de ajudar pessoas em seus processos seletivos e em momentos importantes de suas jornadas.</p>
                    `,
                },
                {
                  name: "Christiana Mello",
                  avatarImg: require("../assets/post/palestra-5/autor2-palestra-5.jpg"),
                  jobTitle: "Diretora de Crescimento da Unidade Recrutadores Catho",
                  type: "Sobre as palestrantes", // palestrante, podcaster, escritor
                  description: `
                    <p>Christiana é Chief Growth Officer da Unidade Recrutadores da Catho desde 2021. Possui mais de 20 anos de experiência em Negócios e Marketing, atuou em empresas de grande porte e Startups  no Brasil e em diversos países da América Latina, incluindo: Tim, Vivo, GVT, Embratel e Tivit. Sua experiência passa por liderar áreas e projetos de: Marketing, Desenvolvimento de Negócios, Canais de Vendas  e Transformação Digital.</p>
                    <p>É Administradora de Empresas formada e pós-graduada pela FGV - SP. Adora viajar e conhecer novas culturas.  Por último e talvez o mais importante, é  mãe da Isabella (12) e do Pedro (10), casada, participa da comunidade de líderes e é Mentora voluntária da Fundação Estudar. </p>
                    `,
                },
              ],
              relatableContent: [],
              seeMore: [
                // Áudio 05
                // {
                //   id: "02",
                //   slug: "7-frases-para-voce-se-destacar-na-entrevista",
                //   bgImg: require("../assets/post/audio-5/audio-05.png"),
                //   type: "áudio",
                //   datetime: "2022-05-30 18:00:00",
                //   shortDescription: "7 frases para você se destacar na entrevista",
                // },
                // Artigo 05
                {
                  id: "01",
                  slug: "comportamentos-que-vao-te-eliminar-da-entrevista-de-emprego",
                  bgImg: require("../assets/post/artigo-5/artigo-05.jpg"),
                  type: "artigo",
                  datetime: "2022-05-30 18:00:00",
                  shortDescription: "Comportamentos que vão te eliminar da entrevista de emprego",
                },
              ],
            },
            // Audio 5
            /* {
              id: "11",
              bgImg: require("../assets/post/audio-5/audio-05.png"),
              slug: "7-frases-para-voce-se-destacar-na-entrevista", // unico
              type: "podcast",
              typeDisplay: "áudio",
              datetime: "2022-05-30 18:00:00",
              duration: "30",
              week: 5,
              shortDescription:
                "7 frases para você se destacar na entrevista",
              description: `
                <audio controls>
                  <source src="${require("../assets/post/audio-5/audio-5.mp3")}" type="audio/mp3">
                  Your browser does not support the audio element.
                </audio>
                
                <p><strong>Sabemos que não somos avaliados apenas pelo nosso perfil técnico em uma entrevista de emprego. A forma como nos comunicamos com o selecionador, demostramos credibilidade, presença, domínio e autoridade sobre a área em que atuamos é, sem dúvidas, o caminho para o sucesso ou fracasso no processo seletivo.</strong></p>
                <p><strong>Você já se perguntou como pode demonstrar esses aspectos comportamentais e habilidades em sua próxima entrevista de emprego?</strong></p>
                <p>Conversamos com a <strong>psicóloga e especialista em recrutamento, Elen Souza,</strong> e separamos para você algumas frases-chave, que te ajudarão a passar credibilidade neste momento. Conheça essas frases e entenda o porquê que cada uma delas pode te ajudar a passar segurança na sua próxima entrevista de emprego</p>
                <p><strong>1 – “Percebo que a comunicação é uma importante ferramenta para a resolução de problemas e  uma forma para trazer melhorias, dentro e fora das organizações.”</strong></p>
                <p>A comunicação é uma habilidade muito importante para um profissional, seja qual for a sua área de atuação. Mostrar na entrevista que você entende isso e aplica no seu dia a dia profissional pode lhe render alguns pontos.</p>
                <p><strong>2 – “Em meu último Feedback, meu gestor mencionou minha habilidade para…” Cite uma qualidade sua.</strong></p>
                <p>É normal que os recrutadores questionem suas qualidades e defeitos, mas você pode se antecipar e citá-los antes de ser perguntado. Isso demonstra o seu autoconhecimento, uma habilidade importante e muito requirida atualmente.</p>
                <p><strong>3 – “Eu me orgulho por se reconhecido por cumprir horários e prazos.”</strong></p>
                <p>O brasileiro, infelizmente, é reconhecido por não dar muita importância para horários e prazos, no entanto, no ambiente corporativo, essa habilidade tem sido cada vez mais exigida. Como dica extra, esse comportamento deve começar antes da entrevista de fato. Ao ser contatado, anote nome do recrutador, data, horário e local da entrevista e chegue com antecedência.</p>
                <p><strong>4 – “Meus colegas sempre falam da minha facilidade em seguir orientações.”</strong></p>
                <p>Saber “se virar” muitas vezes salva o seu dia a dia no trabalho, mas e quando o assunto é seguir orientações, como você se sai? Todo gestor  espera que sua equipe já tenha essa habilidade, por isso, na entrevista vale a pena ressaltar essa competência.</p>
                <p><strong>5 – “Eu fui reconhecido por tomar iniciativas em falar sobre coisas que precisavam ser abordadas.”</strong></p>
                <p>Dizer não e abordar assuntos negativos nem sempre é fácil mas. no dia a dia das organizações, erros e acertos fazem parte, sendo assim é saudável e natural falar sobre isso. Mesmo assim, o profissional brasileiro é reconhecido por ter dificuldade de ter essa habilidade. Mostre que você não tem esse problema. Vale ressaltar que você não deve mentir, trabalhe esta habilidade e seja sincero, caso você ainda tenha dificuldade, melhor evitar essa frase.</p>
                <p><strong>6 – “Minha equipe sempre me elogiou pela facilidade que tenho em integrar.”</strong></p>
                <p> Já falamos aqui que comunicação é uma habilidade fundamental, mas além disso, você é uma pessoa que integra? As pessoas se sentem acolhidas por você, se sim, aproveite para mencionar na entrevista e mostre que você manda bem nos trabalhos em equipe.</p>
                <p><strong>7 – “O meu plano de carreira baseia-se em…”</strong></p>
                <p>Tenha um plano para sua carreira, isso é fundamental e na entrevista você pode mencioná-lo, essa frase pode colocar você em uma situação bem favorável, afinal você mostrará que tem domínio de sua carreira e sabe o que quer da sua vida profissional.</p>
                <p>Lembre-se, não precisa usar todas, escolha as que você se sente mais confortável e mande bem na entrevista! Não tenha essas frases como mandatórias também avalie o contexto da conversa com o selecionar e espere o momento certo para isso, cada entrevista tem um andamento diferente, por isso é bom entender quando você pode usar uma das frases.</p>
                <p>Tenha em mente que enquanto você não está fornecendo evidências de suas <a href="https://www.catho.com.br/carreira-sucesso/carreira/dicas-emprego/10-habilidades-imprescindiveis-num-profissional/" rel="nofollow">habilidades</a> com qualquer uma dessas declarações, você está elevando a percepção do selecionador apenas com o que ele conseguiu captar de seu perfil, baseado na correlação com suas respostas. Isso se modifica quando se evidencia características apreciadas pelo selecionador com o uso de algumas dessas frases acima.</p>
                <p>Ah, não se esqueça de <a href="https://www.catho.com.br/carreira-sucesso/carreira/por-que-treinar-para-entrevista-e-fundamental/" rel="nofollow">treinar antes de sua entrevista</a>, não para decorar possíveis respostas, mas para se preparar melhor para esse momento e para o uso dessas frases, dentro de sua realidade profissional, se sentindo mais seguro.</p>
                `,
              iframeUrl: require("../assets/post/audio-5/audio-05.png"),
              info: {
                views: "200",
                likes: "20",
                comments: "2",
              },
              speaker: [
                {
                  name: "Roni Silva",
                  avatarImg: require("../assets/post/audio-5/autor-audio-5.jpg"),
                  jobTitle: "",
                  type: "Sobre o autor", // palestrante, autor, escritor
                  description: "Formado em Relações Públicas e Jornalismo e pós-graduado em Marketing Digital pela Universidade Metodista de São Paulo, Roni, possui mais de 10 anos de experiência em Conteúdo, Comunicação Institucional e Relações Públicas, planejamento, revisão, gestão e criação de conteúdos para mídias sociais. Atua hoje nas áreas de relações públicas e comunicação corporativa.",
                },
              ],
              relatableContent: [],
              seeMore: [
                // Palestra 05
                {
                  id: "01",
                  slug: "conheca-as-pegadinhas-das-entrevistas-de-emprego-e-como-ser-aprovado",
                  bgImg: require("../assets/post/palestra-5/palestra-05.jpg"),
                  type: "palestra",
                  datetime: "2022-05-30 18:00:00",
                  shortDescription: "Conheça as “pegadinhas” das entrevistas de emprego e como ser aprovado.",
                },
                // Artigo 05
                {
                  id: "02",
                  slug: "comportamentos-que-vao-te-eliminar-da-entrevista-de-emprego",
                  bgImg: require("../assets/post/artigo-5/artigo-05.jpg"),
                  type: "artigo",
                  datetime: "2022-05-30 18:00:00",
                  shortDescription: "Comportamentos que vão te eliminar da entrevista de emprego",
                },
              ],
            }, */
            // Artigo 05
            {
              id: "13",
              bgImg: require("../assets/post/artigo-5/artigo-05.jpg"),
              slug: "comportamentos-que-vao-te-eliminar-da-entrevista-de-emprego", // unico
              type: "artigo",
              typeDisplay: "artigo",
              datetime: "2022-05-30 18:00:00",
              duration: "30",
              week: 5,
              shortDescription: "Comportamentos que vão te eliminar da entrevista de emprego",
              description: `
                <h2>Comportamentos que vão te eliminar da entrevista de emprego</h2>

                <p>Sabemos que esse papo com o recrutador muitas vezes pode deixar os candidatos nervosos, mas existem alguns comportamentos na entrevista de emprego que fazem com que você seja desclassificado.</p>
                <p>Ficou curioso? Então, descubra agora quais as ações que uma pessoa pode fazer que gera essa exclusão dos processos seletivos.</p>
                <p><strong>Comportamentos na entrevista de emprego que não são legais</strong><br>A entrevista de emprego é a grande oportunidade que qualquer profissional tem para ingressar no mercado de trabalho, iniciar a carreira em uma empresa dos sonhos ou sair do desespero provocado por um longo período de desemprego.</p>
                <p>Porém, essa chance pode ser jogada no lixo se você não tomar cuidado e alterar certos comportamentos na hora de bater aquele papo com o recrutador. Lembre-se que a entrevista é uma oportunidade única e que, do outro lado, existe uma pessoa que estará interpretando as suas respostas e cada comportamento que apresentar.</p>
                <p>Muitos dizem que os seus comportamentos são analisados de forma equivocada pelos entrevistadores. Isto até pode acontecer, mas, na grande maioria dos casos, o recrutador faz uma análise coerente; até porque a sua função é essa: analisar e questionar o comportamento dos candidatos.</p>
                <p>A experiência de conduzir centenas de processos seletivos faz com que a análise do recrutador seja cada vez mais apurada. já a sua experiência sobre análise comportamental pode não ser tão profunda, não é mesmo?!</p>
                <p>Portanto, entender quais são as ações que podem gerar uma má interpretação é fundamental. Veja alguns comportamentos na entrevista de emprego dão arrepios em qualquer recrutador e te deixam mais distante da conquista do emprego:</p>
                <ol>
                    <li style="font-weight: bold;">
                        <p><strong>1 - Não se atentar ao que foi combinado</strong></p>
                    </li>
                </ol>
                <p>Geralmente, as informações sobre a entrevista são combinadas por telefone ou e-mail. Neste momento, o recrutador informa o horário da entrevista, o local e algum outro requisito (como, por exemplo, a apresentação de algum documento ou, até mesmo, o currículo). Não se atentar a estas informações e agir contra elas é a primeira bola fora.</p>
                <p>Anote cada informação que lhe foi passada. Prepare tudo com antecedência para garantir que não cometerá nenhum equívoco. Avalie o trajeto e faça uma previsão de quanto tempo será necessário para chegar no local.</p>
                <p>Se possível, faça o percurso alguns dias antes para entender quanto tempo gasta e se as soluções de transporte estão de acordo. Imprima e separe todos os documentos necessários alguns dias antes.</p>
                <ol start="2">
                    <li style="font-weight: bold;">
                        <p><strong>2 - Errar e apenas pedir desculpa</strong></p>
                    </li>
                </ol>
                <p>Durante a entrevista, o você até pode atrasar, se perder no caminho e não conseguir responder a uma pergunta. Concordo que todos estamos suscetíveis a estes contratempos. Porém, quando cometemos um erro, não basta pedir desculpas. Pedir desculpas demonstra que reconhecemos o erro e que sentimos por isso, mas não resolve o problema.</p>
                <p>Qualquer erro que cometer na entrevista afetará a sua avaliação final, mas quando se esforça para reparar o erro o impacto pode ser minimizado. Todos erramos. Nas empresas, diversos profissionais erram, mas os que crescem profissionalmente são os que assumem a responsabilidade e se esforçam para reparar este erro, mesmo gastando mais tempo, energia ou dinheiro para isso.</p>
                <p>Sendo assim, se perceber que está atrasado, pegue um táxi. Se esquecer um documento, corra para imprimi-lo ou deixe na galeria do celular. Se não souber responder um pergunta de imediato, pense! Pare de pedir desculpas e achar que isto é suficiente, pois não é!</p>
                <ol start="3">
                    <li style="font-weight: bold;">
                        <p><strong>3 - Não se preocupar com a sua comunicação</strong></p>
                    </li>
                </ol>
                <p>Cada pessoa possui as suas características de personalidade. Porém, é importante conhecê-las e trabalhar para melhorá-las de acordo com a situação.</p>
                <p>Uma entrevista de emprego exige uma conversa aberta. Os tímidos devem se esforçar para se comunicar com um nível de detalhe maior do que o normal e falar sobre as suas experiências profissionais e os objetivos futuros com clareza.</p>
                <p>Já os muito comunicativos devem se preocupar em manter uma comunicação profissional. Conversar com o recrutador como se ele fosse o seu melhor amigo de infância pode não ser o caminho ideal. Até porque, quanto mais confortável você está em uma comunicação, mais pode escorregar na utilização de gírias e na apresentação de ideias polêmicas.</p>
                <ol start="4">
                    <li style="font-weight: bold;">
                        <p><strong>4 - Desconhecer as suas características</strong></p>
                    </li>
                </ol>
                <p>Você sabe que o recrutador vai querer te conhecer. Você sabe que ele vai perguntar em quais atividades se dá bem e em quais sente mais dificuldade. Então, que tal pensa sobre isso um dia antes da entrevista?</p>
                <p>A entrevista é a oportunidade que você tem para mostrar o seu diferencial e o porquê vale a pena te contratar. Use esta oportunidade a seu favor, ressaltando suas soft skills e projetos que deram certo.</p>
                <ol start="5">
                    <li style="font-weight: bold;">
                        <p><strong>5 - Não lembrar das suas experiências passadas</strong></p>
                    </li>
                </ol>
                <p>Durante a entrevista, o recrutador tende a avaliar as suas capacidades de acordo com as experiências que teve no passado. Você pode utilizar exemplos da sua vida profissional, acadêmica e, até mesmo, pessoal. É importante que você tenha conhecimento de situações da sua vida que comprovam as suas atuais competências.</p>
                <p>Pense um pouco: quais as suas grandes habilidades? Cite, pelo menos, uma experiência que comprove que possui uma habilidade específica. Faça este exercício antes da entrevista. Isto deixará as suas ideias prontas para quando o recrutador te perguntar.</p>
                <p>Caso contrário, demonstrará que não se preocupa muito com o seu histórico profissional e que, talvez, não consiga tirar aprendizados das suas experiências e isto é crítico.</p>
                <ol start="6">
                    <li style="font-weight: bold;">
                        <p><strong>6 - Ter um currículo desatualizado e com erros</strong></p>
                    </li>
                </ol>
                <p>O currículo é como se fosse um cartão de visitas, nele você coloca seus dados, experiências profissionais e qualificação. Imagina apresentar essas grandes informações com erros de português ou com coisas de anos atrás?</p>
                <p>Não atualizar corrigir o seu CV pode demonstrar aos recrutadores que você não dá a devida atenção a um documento tão importante. É por meio dele que as empresas saberão quem é você e o quão apto está para fazer parte do time.</p>
                <p>Se tiver alguma dúvida de como criar o seu cartão da melhor maneira, baixe gratuitamente o nosso <a href="https://www.catho.com.br/carreira-sucesso/category/modelo-curriculo/">Modelo de Currículo</a>. Basta preencher com seus dados.</p>
                <p>Ser flexível e estar disposto(a) a mudar os seus comportamentos na entrevista de emprego é caminho mais curto para a sua recolocação. Se continuar agindo da mesma forma, só fará com que obtenha os mesmos resultados. Você tem demonstrado algum dos comportamentos listados acima? O que precisa mudar?</p>
                <p>Conte para nós compartilhando o link desse texto lá nas suas redes sociais. Boa sorte!</p>
              `,
              iframeUrl: require("../assets/post/artigo-5/artigo-05-interna.png"),
              info: {
                views: "200",
                likes: "20",
                comments: "2",
              },
              speaker: [
                {
                  name: "Maria Eduarda Goncalves Pereira",
                  avatarImg: require("../assets/post/artigo-5/autor-artigo-5.jpg"),
                  /* jobTitle: "Jovem aprendiz no setor de Marketing Mídia", */
                  type: "Sobre a autora", // palestrante, autor, escritor
                  description:
                    "Jovem aprendiz no setor de Marketing Mídia pela empresa Catho, desenvolvendo conteúdos para o blog da empresa. Trabalhou em outra organização na área de design e atendimento. Motivada a crescer profissionalmente e ganhar conhecimento.",
                },
              ],
              relatableContent: [],
              seeMore: [
                // Palestra 05
                {
                  id: "01",
                  slug: "conheca-as-pegadinhas-das-entrevistas-de-emprego-e-como-ser-aprovado",
                  bgImg: require("../assets/post/palestra-5/palestra-05.png"),
                  type: "palestra",
                  datetime: "2022-05-30 18:00:00",
                  shortDescription: "Conheça as “pegadinhas” das entrevistas de emprego e como ser aprovado.",
                },
                // Áudio 05
                // {
                //   id: "02",
                //   slug: "7-frases-para-voce-se-destacar-na-entrevista",
                //   bgImg: require("../assets/post/audio-5/audio-05.png"),
                //   type: "áudio",
                //   datetime: "2021-05-31 10:00:00",
                //   shortDescription: "7 frases para você se destacar na entrevista",
                // },
              ],
            },
          ],
        },
      ],
    },
  };
}
