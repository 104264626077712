import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Event from "../../components/Event";
import { content } from "../../config/agenda";
import SearchBar from "../../components/SearchBar";
import ButtonScrollTop from "../../components/ButtonScrollTop";

export default function Resultados() {
  const {
    historyBackText,
    text,
    resultTextFinal,
    resultTextInitial,
  } = content.search;

  const { title, subtitle, searchBarPlaceholder } = content.newSearch;

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const searchTemplate = [
    {
      id: "02",
      slug: "rh-coca-cola",
      bgImg: require("../../assets/images/thumb.png"),
      type: "palestra",
      datetime: "2020-04-21 14:30:00",
      shortDescription:
        "O segredo da contratação: lorem ipsum dolor sit amet lorem ipsum dolor sit amet",
    },
    {
      id: "01",
      slug: "rh-coca-cola",
      bgImg: require("../../assets/images/thumb.png"),
      type: "palestra",
      datetime: "2020-04-21 14:30:00",
      shortDescription:
        "O segredo da contratação: lorem ipsum dolor sit amet lorem ipsum dolor sit amet",
    },
    {
      id: "03",
      slug: "rh-coca-cola",
      bgImg: require("../../assets/images/thumb.png"),
      type: "palestra",
      datetime: "2020-04-21 14:30:00",
      shortDescription:
        "O segredo da contratação: lorem ipsum dolor sit amet lorem ipsum dolor sit amet",
    },
    {
      id: "04",
      slug: "rh-coca-cola",
      bgImg: require("../../assets/images/thumb.png"),
      type: "palestra",
      datetime: "2020-04-21 14:30:00",
      shortDescription:
        "O segredo da contratação: lorem ipsum dolor sit amet lorem ipsum dolor sit amet",
    },
    {
      id: "05",
      slug: "rh-coca-cola",
      bgImg: require("../../assets/images/thumb.png"),
      type: "palestra",
      datetime: "2020-04-21 14:30:00",
      shortDescription:
        "O segredo da contratação: lorem ipsum dolor sit amet lorem ipsum dolor sit amet",
    },
    {
      id: "06",
      slug: "rh-coca-cola",
      bgImg: require("../../assets/images/thumb.png"),
      type: "palestra",
      datetime: "2020-04-21 14:30:00",
      shortDescription:
        "O segredo da contratação: lorem ipsum dolor sit amet lorem ipsum dolor sit amet",
    },
  ];

  let query = useQuery();
  return (
    <section className="search">
      <div className="container">
        <div className="row">
          <div className="search__header">
            <Link
              to="/"
              className="single-header__back mb-8 d-block color-black"
            >
              <img
                src={require("../../assets/images/icon-chevron-black.svg")}
                alt="Arrow Back"
                className="single-header__back-arrow"
              />
              {historyBackText}
            </Link>
            <h2 className="search__title mb-2 ">{text}</h2>
            <p className="search__result">
              {resultTextInitial} 6 {resultTextFinal} <strong>"{query}"</strong>
            </p>
          </div>
        </div>
        <div className="row">
          {searchTemplate.map((event) => {
            return (
              <Event className="col-lg-6 mb-6" info={event} key={event.id} />
            );
          })}
        </div>

        <div className="row">
          <div className="newsearch__content">
            <p className="newsearch__text text-center">{title}</p>
            <p className="newsearch__text text-center mb-6">
              <strong>{subtitle}</strong>
            </p>
            <SearchBar className="d-block">{searchBarPlaceholder}</SearchBar>
          </div>
        </div>
      </div>
      <ButtonScrollTop />
    </section>
  );
}
