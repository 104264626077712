import React from "react";
import { Link } from "react-router-dom";
import DateTime from "../../components/DateTime";
import Category from "../../components/Category";

export default function SingleHeader({ title, type, typeDisplay, datetime }) {
  return (
    <section className="single-header">
      <Link to="/" className="single-header__back mb-8 d-block color-black">
        <img
          src={require("../../assets/images/icon-chevron-black.svg")}
          alt="Arrow Back"
          className="single-header__back-arrow"
        />
        Voltar
      </Link>
      <div className="single-header__infos">
        <h1 className="single-header__infos title title--single">{title}</h1>
        <div className="single-header__group">
          <Category type={type} typeDisplay={typeDisplay} />
          <DateTime className="btn--outline-black" datetime={datetime} />
        </div>
      </div>
    </section>
  );
}
