import React, { useState } from 'react';
import { Element } from 'react-scroll';
import Hero from '../../section/Hero';
import CallToAction from '../../section/CallToAction';
import NewsLetter from '../../section/NewsLetter';
import Events from '../../section/Events';
import Faq from '../../section/Faq';
import ModalRegister from '../../components/ModalRegister';
import ButtonScrollTop from '../../components/ButtonScrollTop';

export default function Home() {
  const [modalRegisterActive, setModalRegisterActive] = useState(false);
  const [showView, setShowView] = useState('form');

  return (
    <>
      <Hero
        toggleModal={() => {
          setModalRegisterActive(!modalRegisterActive);
          setShowView('form');
        }}
      />
      <Element name='cta'>
        <CallToAction
          toggleModal={() => {
            setModalRegisterActive(!modalRegisterActive);
            setShowView('form');
          }}
        />
      </Element>
      <Element name='agenda'>
        <Events />
      </Element>
      <NewsLetter
        toggleModal={() => {
          setModalRegisterActive(!modalRegisterActive);
          setShowView('feedback');
        }}
      />

      <Element name='faq'>
        <Faq />
      </Element>

      <ModalRegister
        isActive={modalRegisterActive}
        toggleModal={() => {
          setModalRegisterActive(!modalRegisterActive);
          setShowView('form');
        }}
        showView={showView}
        setShowView={setShowView}
      />

      <ButtonScrollTop />
    </>
  );
}
