import React from "react";

export default function Category({ type, typeDisplay }) {
  return (
    <span
      className={`mr-2 btn btn--small btn--round category category--${type} text-uppercase`}
    >
      {typeDisplay}
    </span>
  );
}
