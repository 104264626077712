/** Change this flag to false if you want to prevent pre-rendering the thirdpart.js file
 * If it set to false, the react-snap post build prerender won't load this file
 * I tested both versions at google page speed, and the page load faster if the scripts
 * are prerendered, thats strange but i will leave the code here to future tests
 */

const loadAsyncScripts = callback => {
    if (navigator.userAgent !== 'ReactSnap') {
        const existingScript = document.getElementById('thirdPartScripts');
        if (!existingScript) {
            
            const script = document.createElement('script');
            const basename = document.querySelector('base')?.getAttribute('href') ?? '/';
            script.src = `${basename}/thirdpart.js`;
            script.id = 'thirdPartScripts';
            script.async = 'true';
            
            document.body.appendChild(script);

            script.onload = () => {
                if (callback) callback();
            };
        }

        if (existingScript && callback) callback();
    }
};

export default loadAsyncScripts; 