import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { content } from '../../config/home';
import Event from '../../components/Event';

function Events({ contents }) {
  const { title } = content.events;

  return (
    <section className='events'>
      <div className='container'>
        <div className='events__content'>
          <h3 className='title title--section mb-8'>{title}</h3>
          {contents.map((content) => {
            return <ListEvents key={content.week} week={content} />;
          })}
        </div>
      </div>
    </section>
  );
}

const isDisabled = (eventDatetime, type) => {
  const currentDate = moment.tz('America/Sao_Paulo');
  const eventDate = moment.tz(eventDatetime, 'America/Sao_Paulo');

  if (type === 'palestra') {
    eventDate.subtract(30, 'minutes');
  }

  return eventDate >= currentDate;
}

function ListEvents({ week }) {
  return (
    <div
      className={`events__list_${week.week}`} 
    >
      <p className='events__week-name color-pink mb-6'>
        <img
          src={require('../../assets/images/icon-chevron-pink.svg')}
          className='events__week-icon'
          alt=''
        />
        Semana {week.week}
      </p>
      <div className='row events__itens justify-content-center'>
        {week.events.map((event) => {
          return (
            <Event
              className='col-lg-6 mb-6'
              info={event}
              key={event.id}
              disabled={isDisabled(event.datetime, event.type)}
              origin={'Events'}
            />
          );
        })}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    ...state.content,
  };
}

export default connect(mapStateToProps)(Events);
