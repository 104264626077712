import React from 'react';
import { content } from '../../config/home';
import { Parallax } from 'react-parallax';
import Fluid1 from '../../assets/images/fluid-1.png';
import Fluid2 from '../../assets/images/fluid-2.png';
import Fluid4 from '../../assets/images/fluid-4.png';

export default function Hero({ toggleModal }) {
  const { subtitle, title } = content.cta;

  return (
    <section className='cta bg-blue'>
      <div className="parallax left">
        <Parallax blur={2} bgImage={Fluid2} bgImageAlt="Bolha 2" strength={80} className="fluid-2" />
        <Parallax blur={5} bgImage={Fluid1} bgImageAlt="Bolha 1" strength={220} className="fluid-1" />
      </div>
      <div className="parallax right">
        <Parallax blur={2} bgImage={Fluid4} bgImageAlt="Bolha 4" strength={180} className="fluid-4" />
      </div>
      <div className='cta__content'>
        <h3 className='cta__title text-center color-white mb-3 mb-lg-7'>
          {title}
        </h3>
        <p className='cta__paragraph color-white text-center' dangerouslySetInnerHTML={{ __html: subtitle }} />
      </div>
    </section>
  );
}
