import React from 'react';
import { content } from '../../config/home';

export default function Hero() {
  const { description, title, logo } = content.hero;

  return (
    <section className='hero' name='hero'>
      <div className='hero__content gtm-class'>
        <div className="hero__logo__container">
          <img src={logo} alt='' className='hero__logo' />
        </div>
        <div className="hero__text">
          <h2 className='hero__title color-white mb-10' dangerouslySetInnerHTML={{ __html: title }}></h2>
          <h2 className='hero__description color-white mb-10' dangerouslySetInnerHTML={{ __html: description }}></h2>
        </div>
      </div>
    </section>
  );
}
