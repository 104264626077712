import { content } from '../config/global';

const axios = require('axios');
const options = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': content.register.token,
    InvocationType: 'Event',
  },
};

export default {
  register: async (form) => {
    const body = {
      event: 'direct-event',
      origin: 'worktalks_lp',
      data: {
        name: form.get('name'),
        email: form.get('email'),
        phone: form.has('phone') ? form.get('phone').replace(/[^0-9]+/g, '') : null
      }
    };
    return await axios.post(content.register.url, body, options);
  }
}