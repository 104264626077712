import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import GoogleAnalytics from '../GoogleAnalytics';

function SearchBar(props) {
  const [search, setSearch] = useState('');

  function handleSumbit(evt) {
    evt.preventDefault();
    props.history.push(`/resultados?q=${search}`);
  }

  return (
    <div className={`search-bar ${props.className}`}>
      <form onSubmit={handleSumbit} className="gtm-class">
        <input
          type='text'
          className='search-bar__input'
          placeholder={props.children}
          value={search}
          onChange={(evt) => {
            setSearch(evt.currentTarget.value);
          }}
          {...GoogleAnalytics.trackEvent('lp-worktalks:interacao', 'onblur:pesquisa')}
        />
        <input type='submit' className='sr-only' />
      </form>
    </div>
  );
}

export default withRouter(SearchBar);
