import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TheFooter from './components/TheFooter';
import TheHeader from './components/TheHeader';
import loadAsyncScripts from './components/Html/loadAsyncScripts';
import TheRouter from './router';
import { setContent } from './store/content';
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom'

function App({ setContent }) {
  const [firstCall, setFirstCall] = useState(true);
  const metaTags = useState({ 'og:title': '', 'og:description': '', 'og:image': '', 'og:type': ''})[0]
  const history = useHistory();
  const hash = window.location.hash;

  if (hash.startsWith('#/')) {
    history.replace(hash.replace('#', ''));
  }

  useEffect(() => {
    if (firstCall) {
      setContent();
      setFirstCall(false);
      window.addEventListener('load', () => {
        loadAsyncScripts();
      });
    }
  }, [firstCall, setContent]);

  function setMetaTags(tags) {
    tags.map(t => metaTags[t.property] = t.content);
  }

  return (
    <>
      <Helmet onChangeClientState={(h) => { setMetaTags(h.metaTags) }}>
        <meta property="og:title" content="Worktalks Catho | Lives e Conteúdos Grátis em Maio" />
        <meta property="og:description" content="Faça sua inscrição para assistir as lives com especialistas, ter acesso a textos e áudios sobre os assuntos mais atuais do mercado de trabalho!" />
        <meta property="og:image" content="/facebook_share_image.jpg" />
        <meta property="og:type" content="website" />
      </Helmet>
      <TheHeader />
      <main>
        <TheRouter metaTags={metaTags} setMetaTags={setMetaTags} />
      </main>
      <TheFooter />
    </>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setContent }, dispatch);

export default connect(null, mapDispatchToProps)(App);
