import React from 'react';
import { content } from '../../config/singleEvent';

export default function SinglePreview({
  likes,
  views,
  comments,
  type,
  setModalShareActive,
  iFrameUrl,
}) {
  const buildYouTubeUrl = (iFrameUrl) => {
    return iFrameUrl + '?enablejsapi=1&autoplay=1';
  };

  const contentByType = {
    palestra: <YoutubeContent url={buildYouTubeUrl(iFrameUrl)} />,
    podcast: <ImageContent url={iFrameUrl} />,
    artigo: <ImageContent url={iFrameUrl} />,
  };
  
  const { viewsText, likesText, commentsText, shareText } = content.iframe;

  return (
    <section className='single-preview'>
      <div className='single-preview__content'>{contentByType[type]}</div>
      <div className='single-preview__infos'>
        <div className='single-preview__numbers d-none'>
          <div className='single-preview__item single-preview__item-views'>
            <img src={require('../../assets/images/icon-play.svg')} alt='' />
            <p>{`${views} ${viewsText}`}</p>
          </div>
          <div className='single-preview__item single-preview__item-likes'>
            <img src={require('../../assets/images/icon-heart.svg')} alt='' />
            <p>{`${likes} ${likesText}`}</p>
          </div>
          <div className='single-preview__item single-preview__item-ballon'>
            <img src={require('../../assets/images/icon-ballon.svg')} alt='' />
            <p>{`${comments} ${commentsText}`}</p>
          </div>
        </div>
        <button
          onClick={() => {
            setModalShareActive(true);
          }}
          className='ml-lg-auto single-preview__share btn btn--share btn--round bg-blue color-white'
        >
          <img src={require('../../assets/images/icon-share.svg')} alt='' />
          <p>{shareText}</p>
        </button>
      </div>
    </section>
  );
}

function YoutubeContent({ url }) {
  return (
    <iframe
      width='560'
      height='315'
      src={url}
      frameBorder='0'
      allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
      title='Palestra'
      className='single-preview__content single-preview__content--palestra'
    ></iframe>
  );
}

function ImageContent({ url }) {
  return (
    <img
      src={url}
      alt=''
      className='single-preview__content single-preview__content--artigointerno'
    />
  );
}
