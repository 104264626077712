export default class GoogleAnalytics {
  static trackEvent = (label, action) => {
    return {
      'data-gtm-event-category': 'candidatos:catho',
      'data-gtm-event-label': label,
      'data-gtm-event-action': action,
    }
  };

  static trackPageview = (page) => {
    function checkVariable() {
      if (window.trackerPageAnalytics) {
        window.trackerPageAnalytics.variablesDataLayer.event = "virtual-pageview";
        window.trackerPageAnalytics.variablesDataLayer.eventName = "interaction:normal-metric";
        window.trackerPageAnalytics.variablesDataLayer.page = page;
        window.trackerPageAnalytics.send();
      } else {
        setTimeout(checkVariable, 1000);
      }
    }
   
    checkVariable();
  };
}
