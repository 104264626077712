import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { content } from '../../config/global';
import { connect } from 'react-redux';
import SearchBar from '../SearchBar';
import LiveWarning from '../LiveWarning';
import { scroller } from 'react-scroll';
import GoogleAnalytics from '../GoogleAnalytics';
// import Moment from 'react-moment';
import 'moment-timezone';
import moment from 'moment';

function TheHeader({ history, contents }) {
  const { link, logo, logoWhite, searchBarText } = content.header;
  const [itemActive, setItemActive] = useState(null);
  const [menuActive, setmenuActive] = useState(false);
  const [isLive, setLive] = useState(false);
  const [eventName, setEventName] = useState('');
  const [eventSlug, setEventSlug] = useState('');

  function handleGoHome(scrollTo, itemActive) {
    setItemActive(itemActive);
    history.push('/');
    setTimeout(() => {
      scroller.scrollTo(scrollTo, {
        duration: 300,
        smooth: true,
        offset: isLive ? -120 : -70,
      });
      closeMenu();
    }, 120);
  }

  function openMenu() {
    setmenuActive(true);
  }

  function closeMenu() {
    setmenuActive(false);
  }

  useEffect(() => {

    contents.map((week) => week.events
      .filter((event) => event.type === 'palestra')
      .forEach((event) => {
        const start = moment.tz(event.livetime, 'America/Sao_Paulo').subtract(5, 'minutes');
        const end = moment.tz(event.livetime, 'America/Sao_Paulo').add(event.duration, 'minutes');
        const isBetween = moment.tz('America/Sao_Paulo').isBetween(start, end);
        if(isBetween) {
          setLive(true);
          setEventName(event.shortDescription);
          setEventSlug(event.slug);
        }
      }));
      
  }, [contents]);

  return (
    <header className='header'>
      <LiveWarning
        isLive={isLive}
        title={eventName}
        linkPalestra={eventSlug}
        toggleWarning={() => {
          setLive(false);
        }}
      />
      <nav className='header__content gtm-class'>
        <Link 
          to={{ pathname: "https://www.catho.com.br" }} 
          target="_blank" 
          className='header__logo'
          onClick={() => {
            handleGoHome('hero');
          }}
          {...GoogleAnalytics.trackEvent(
            'lp-worktalks:interacao',
            'link:catho'
          )}
        >
          <img src={logo} alt='' />
        </Link>
        <div className={menuActive ? 'header__menu active' : 'header__menu'}>
          <div className='header__menu-header'>
            <Link
              to={{ pathname: "https://www.catho.com.br" }} 
              target="_blank" 
              className='header__logo'
              onClick={() => {
                handleGoHome('hero');
              }}
              {...GoogleAnalytics.trackEvent(
                'lp-worktalks:interacao',
                'link:catho'
              )}
            >
              <img src={logoWhite} alt='' />
            </Link>
            <button className='header__hamburger p-0 btn btn-link'>
              <img
                onClick={closeMenu}
                src={require('../../assets/images/icon-close-thin.svg')}
                alt='Menu'
              />
            </button>
          </div>
          <ul className='header__nav gtm-class'>
            <li>
              <button
                className={
                  itemActive === 'event'
                    ? 'header__nav-item header-active'
                    : 'header__nav-item'
                }
                onClick={() => {
                  handleGoHome('cta', 'event');
                }}
                {...GoogleAnalytics.trackEvent(
                  'lp-worktalks:interacao',
                  'link:evento'
                )}
              >
                {link.event}
              </button>
            </li>
            <li>
              <button
                className={
                  itemActive === 'agenda'
                    ? 'header__nav-item header-active'
                    : 'header__nav-item'
                }
                onClick={() => {
                  handleGoHome('agenda', 'agenda');
                }}
                {...GoogleAnalytics.trackEvent(
                  'lp-worktalks:interacao',
                  'link:agenda'
                )}
              >
                {link.agenda}
              </button>
            </li>
            <li>
              <button
                className={
                  itemActive === 'questions'
                    ? 'header__nav-item header-active'
                    : 'header__nav-item'
                }
                onClick={() => {
                  handleGoHome('faq', 'questions');
                }}
                {...GoogleAnalytics.trackEvent(
                  'lp-worktalks:interacao',
                  'link:tire-duvidas'
                )}
              >
                {link.questions}
              </button>
            </li>
          </ul>
          <img
            className='logo-talks'
            src={require('../../assets/images/logo-work-talks.svg')}
            alt='Work talks'
          />
        </div>
        <button
          className='header__hamburger p-0 btn btn-link'
          onClick={openMenu}
        >
          <img
            src={require('../../assets/images/icon-menu-hamburger.svg')}
            alt='Menu'
          />
        </button>
        <SearchBar>{searchBarText}</SearchBar>
      </nav>
    </header>
  );
}

function mapStateToProps(state) {
  return {
    ...state.content,
  };
}
export default connect(mapStateToProps)(withRouter(TheHeader));
