import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import GoogleAnalytics from '../GoogleAnalytics';

export default function CustomAccordion(props) {
  const { accordionItens } = props;

  return (
    <Accordion>
      {accordionItens.map((item, index) => {
        return (
          <>
            <AccordionItem
              question={item.question}
              answer={item.answer}
              slug={item.slug}
              index={index + 1}
              key={index + 1}
            />
          </>
        );
      })}
    </Accordion>
  );
}

function AccordionItem(props) {
  const { question, answer, index, slug } = props;
  return (
    <div className='accordion__item gtm-class'>
      <CustomToggle eventKey={index} slug={slug}>{question}</CustomToggle>
      <Accordion.Collapse eventKey={index}>
        <div className='accordion__answer'>
          <p>{answer}</p>
        </div>
      </Accordion.Collapse>
    </div>
  );
}

function CustomToggle({ children, eventKey, slug }) {
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    const allItens = document.querySelectorAll('.accordion__question');
    const isAlreadyActive = allItens[eventKey - 1].classList.contains('is-active');

    allItens.forEach((item) => {
      if (item.classList.contains('is-active')) {
        item.classList.remove('is-active');
      }
    });

    if (!isAlreadyActive) {
      allItens[eventKey - 1].classList.add('is-active');
    }
  });

  return (
    <button
      type='button'
      className='accordion__question'
      onClick={decoratedOnClick}
      {...GoogleAnalytics.trackEvent('lp-worktalks:interacao', `link:duvidas:${slug}`)}
    >
      {children}
      <img
        className='accordion__question-arrow'
        src={require('../../assets/images/icon-chevron-black.svg')}
        alt=''
      />
    </button>
  );
}
