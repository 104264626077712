import React, { useEffect, useState } from "react";
import GoogleAnalytics from "../GoogleAnalytics";

export default function ButtonScrollTop(props) {
  const [show, setShow] = useState(false);
  const [firstCall, setFirstCall] = useState(true);

  useEffect(() => {
    if (firstCall) {
      document.addEventListener("scroll", function (e) {
        scrollShow();
      });
      setFirstCall(false);
    }
  }, [firstCall]);

  function scrollTop() {
    window.scrollTo(0, 0);
  }

  function scrollShow() {
    if (window.scrollY > 800) {
      setShow(true);
    } else {
      setShow(false);
    }
  }

  return (
    <button
      onClick={scrollTop}
      className={(show ? "buttonscrolltop" : "hide") + " gtm-class"}
    >
      <div
        {...GoogleAnalytics.trackEvent("lp-worktalks:interacao", "botao:topo")}
      >
        <img
          src={require("../../assets/images/icon-chevron-left-white.svg")}
          alt="chevron"
          className="buttonscrolltop__chevron"
        />

        <p className="buttonscrolltop__text">TOPO</p>
      </div>
    </button>
  );
}
