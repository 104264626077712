import React from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import 'moment-timezone';
import DateTime from '../DateTime';
import Category from '../Category';
import GoogleAnalytics from '../GoogleAnalytics';

export default function Event(props) {
  const { bgImg, type, datetime, shortDescription, slug } = props.info;
  return (
    <article
      className={
        props.disabled
          ? `event ${props.className} is-disabled`
          : `event ${props.className}`
      }
    >
      <div className='event__group gtm-class'>
        <Link
          to={`/evento/${slug}`}
          className='event__link'
          {...GoogleAnalytics.trackEvent(
            'lp-worktalks:interacao',
            (
              props.origin === 'Events'
              ? `link:${type}:${slug}:ver-mais`
              : (
                props.origin === 'SingleContent'
                ? `link:veja-tambem:${type}:${slug}`
                : `undefined:${type}:${slug}:ver-mais`
              )
            )
          )}
        >
          <img src={bgImg} alt='Event' className='event__bg' />
          <div className='event__content'>
            <div className='event__infos'>
              <Category type={type} />
              <DateTime datetime={datetime} />
            </div>
            <p className='event__text'>{shortDescription}</p>
          </div>
          <div className='event__overlay'>
            <img
              src={require('../../assets/images/icon-plus.svg')}
              alt=''
              className='event__overlay-img'
            />
            <p className='event__overlay-text text-center color-white'>
              ver mais
            </p>
          </div>
        </Link>
        <span className='event__warning'>
          <img
            src={require('../../assets/images/icon-calendar-white.svg')}
            className='mr-2'
            alt='Calendário'
          />
          DISPONÍVEL EM
          <Moment className='mr-1 ml-1' tz='America/Sao_Paulo' format='DD/MM'>
            {datetime}
          </Moment>
          ÀS
          <Moment className='mr-1 ml-1' tz='America/Sao_Paulo' format='HH:mm'>
            {datetime}
          </Moment>
        </span>
      </div>
    </article>
  );
}
