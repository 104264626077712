import React from 'react';
import { content } from '../../config/global';
import Accordion from '../../components/Accordion';
import { Parallax } from 'react-parallax';
import Fluid3 from '../../assets/images/fluid-3.png';
import Fluid4 from '../../assets/images/fluid-4.png';


export default function Faq() {
  const { title } = content.faq;

  const accordionItens = [
    {
      question: 'O que é o Worktalks?',
      slug: 'o-que-e-o-worktalks',
      answer: 'Um evento online e gratuito para ajudar pessoas em seus próximos passos profissionais sem sair de casa. A 4ª edição do Worktalks aconteceu durante todo o mês de maio 2022 e contou com conteúdos em formatos diversos como: lives, textos e áudios.',
    },
    /* {
      question: 'Quando?',
      slug: 'quando',
      answer: 'O Worktalks acontecerá durante todo o mês de maio. Serão bate-papos com conteúdos diversos sobre o mercado de trabalho, às 18h, nos dias 02/05, 09/05, 16/05, 23/05 e 30/05.',
    }, */
    {
      question: 'Onde?',
      slug: 'onde',
      answer: 'O evento é online, no site: www.worktalks.com.br. Você também pode assistir os episódios desta edição no canal oficial da Catho, no Youtube.',
    },
    /* {
      question: 'Como faço para me inscrever?',
      slug: 'como-facao-para-me-inscrever',
      answer: 'Acesse o site www.worktalks.com.br e clique em "Inscreva-se agora", em seguida preencha seus dados pessoais nos campos em branco. Pronto! Você será notificado que está inscrito na mesma tela, após finalizar o preenchimento.',
    }, */
    /* {
      question: 'Quem pode participar?',
      slug: 'quem-pode-participar',
      answer: 'Todo mundo! #VemProWorktalks, basta se cadastrar para ter acesso a todos os conteúdos. Ah, é totalmente gratuito!',
    }, */
    {
      question: 'Quanto custa?',
      slug: 'quanto-custa',
      answer: 'O Worktalks é inteiramente gratuito, basta acessar os conteúdos aqui ou canal oficial da Catho, no Youtube.',
    },
    {
      question: 'Posso acessar quantos conteúdos?',
      slug: 'posso-acessar-quantos-conteudos',
      answer: 'Todos que quiserem! No Worktalks, não há limite para o compartilhamento de conhecimento.',
    },
    /* {
      question: 'Como assistir?',
      slug: 'como-assistir',
      answer: 'Acesse o site www.worktalks.com.br e clique na palestra desejada. Em seguida, abrirá uma página com os detalhes da live. Basta apertar o play sobre o vídeo para começar.',
    }, */
    {
      question: 'Qual a duração das palestras e vídeos?',
      slug: 'qual-a-duracao-das-palestras-e-videos',
      answer: 'As palestras terão aproximadamente 45min de duração.',
    },
    /* {
      question: 'Onde posso assistir às palestras após a transmissão?',
      slug: 'pososo-assistir-as-palestras-apos-a-transmissao',
      answer: 'As gravações continuam disponíveis no site do Worktalks e também em nosso canal do YouTube.',
    }, */
    {
      question: 'Como posso compartilhar o Worktalks com meus amigos e familiares?',
      slug: 'como-posso-compartilhar-o-worktalks-com-meus-amigos-e-familiares',
      answer: 'Você pode convidar e compartilhar as informações do Worktalks acessando cada um dos conteúdos em “ver mais”. Logo abaixo de cada imagem de destaque, você encontrará o botão “compartilhar”. Agora é só selecionar qual rede social você deseja compartilhar o conteúdo.  Não deixe de incluir a hastag #VemProWorktalks',
    },
    /* {
      question: 'O evento Worktalks disponibiliza declaração de horas de participação?',
      slug: 'o-evento-Worktalks-disponibiliza-declaracao-de-horas-de-participacao',
      answer: 'Sim. Os interessados devem fazer sua solicitação via e-mail: worktalks@catho.com. A declaração será entregue em até 5 dias úteis após o envio das informações solicitadas.',
    }, */
    {
      question: 'Quando acontecerá a próxima edição do Worktalks?',
      slug: 'quando-acontecera-a-proxima-edicao-do-worktalks',
      answer: 'O evento acontece durante o mês de maio. Fiquei ligado nos canais oficiais da Catho e acompanhe as novidades.',
    },

  ];

  return (
    <section className='faq'>
      <div className='container'>
        <div className='faq__content'>
          <h3 className='title title--section mb-4'>{title}</h3>
          <hr className='faq__line d-none d-lg-block' />
          <Accordion className='mt-7' accordionItens={accordionItens} />
        </div>
      </div>
      <div className="parallax right">
        <Parallax blur={1} bgImage={Fluid4} bgImageAlt="Bolha 4" strength={80} className="fluid-4" />
        <Parallax blur={2} bgImage={Fluid3} bgImageAlt="Bolha 1" strength={220} className="fluid-3" />
      </div>
    </section>
  );
}
