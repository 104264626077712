export const content = {
  iframe: {
    viewsText: 'VIEWS',
    likesText: 'LIKES',
    commentsText: 'COMENTÁRIOS',
    shareText: 'COMPARTILHAR',
  },
  sidebar: {
    title: 'Veja também:',
  },
  infos: {
    about: 'Sobre o palestrante',
  },
  comments: {
    title: 'Comentários',
  },
  carousel: {
    title: 'Conteúdos relacionados:',
  },
};
