import React from "react";
import { content } from "../../config/singleEvent";
import Event from "../../components/Event";
import CardAuthor from "../../components/CardAuthor";

export default function SingleContent(props) {
  const { title } = content.sidebar;
  const { speaker, description, seeMore } = props;
  return (
    <section className="single-content">
      <div className="row">
        <div className="col-lg-7 single-content__col">
          <div
            className="single-content__description mb-9"
            dangerouslySetInnerHTML={{ __html: description }}
          />

          <div className="single-content__author bg-gray">
            {
              speaker[0]
              && <p className="single-content__title">{speaker[0].type}</p>
            }
            {speaker.map((speaker) => (
              <CardAuthor
                key={speaker.name}
                type={speaker.type}
                name={speaker.name}
                jobTitle={speaker.jobTitle}
                description={speaker.description}
                avatar={speaker.avatarImg}
              />
            ))}
          </div>
        </div>

        <div className="col-lg-5">
          <div className="single-content__see-more">
            <p className="single-content__see-more-text mb-4">{title}</p>
            {seeMore.map((item) => {
              return (
                <Event
                  className="event--sm mb-8"
                  info={item}
                  key={item.id}
                  disabled={false}
                  origin={"SingleContent"}
                />
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}
