export const content = {
  hero: {
    bgImage: require('../assets/images/hero-banner.jpg'),
    logo: require('../assets/images/hero-logo.png'),
    title:
      'É hora de realizar <br> <span class="color-pink">seus planos.</span>',
    description:
      '<span class="cta__paragraph color-white">Confira tudo que rolou nesta edição do Worktalks e fique por dentro das principais dicas e tendências do mercado de trabalho em 2022.</span> <br><br><span class="cta__paragraph color-white"><strong>Palestras e conteúdos exclusivos <br> online e 100% GRÁTIS.</strong></span>',
    btnText: 'INSCREVA-SE AGORA',
  },
  cta: {
    title:
      'Fique mais preparado para o mercado de trabalho. #VemProWorktalks! Conteúdos totalmente online e gratuito, feito sob medida para você.',
    subtitle:
      'Pensando em te ajudar a construir o seu próximo passo profissional, sem sair de casa, pelo quarto ano consecutivo, a Catho realizou em maio de 2022 o Worktalks. Um mês inteiro abordando assuntos sobre o mercado de trabalho, como conseguir um emprego de forma efetiva, vagas e o mercado de tecnologia, dicas de ouro para construir o currículo e como ter sucesso nas entrevistas. E vale ressaltar que todo esse conteúdo foi abordado por um time de profissionais feras e experientes no assunto, em formatos diversos como: lives e artigos. <br><br> <span class="hero__description color-white">Inscreva-se agora mesmo e garanta a sua vaga!</span>',
    btnText: 'fazer inscrição',
    btnWarning: 'Inscreva-se, agora mesmo, para garantir a sua vaga!',
  },
  events: {
    title: 'Agenda',
  },
};
