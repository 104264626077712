import React from "react";

export default function CardAuthor({
  type,
  name,
  jobTitle,
  description,
  avatar,
}) {
  return (
    <div className="card-author bg-gray">
      {/* <p className='card-author__title'>Sobre o {type}</p> */}
      <div className="card-author__content">
        <img src={avatar} alt="" className="card-author__avatar" />
        <div className="card-author__infos">
          <p className="card-author__base">
            <span className="card-author__base-name color-pink">{name}</span>
            {
              jobTitle
              &&
              <img
                className="card-author__elipsis d-none d-lg-block"
                src={require("../../assets/images/icon-elipse.svg")}
                alt=""
              />
            }
            { jobTitle && <span className="card-author__base-name">{jobTitle}</span> }
          </p>
          <q className="card-author__description d-none d-lg-block" dangerouslySetInnerHTML={{__html: description}}>
          </q>
        </div>
      </div>
      <q className="card-author__description d-lg-none" dangerouslySetInnerHTML={{__html: description}}></q>
    </div>
  );
}
