import React from 'react';
import { content } from '../../config/global';

export default function TheFooter() {
  const { text, footerImg } = content.footer;
  return (
    <footer className='footer bg-black'>
      <a href='https://www.catho.com.br' target='_blank' rel="noopener noreferrer"><img src={footerImg} alt='Logo branco' className='footer__logo mb-3' /></a>
      <p className='footer__text color-white'>{text}</p>
      <p className="text-center" style={{marginTop:10}}>
        <a style={{color: '#fff', textDecoration: 'underline', fontFamily: 'Montserrat', fontSize:12, fontWeight: 'bold'}} rel="noopener noreferrer" href="https://assets.catho.com.br/consents-prod/terms/politica-cookies-privacidade.html" title="Politica de privacidade" target="_blank">Politica de privacidade</a></p>
    </footer>
  );
}
