import React from 'react';
import { content } from '../../config/singleEvent';
import Event from '../../components/Event';
import Slider from 'react-slick';

export default function SingleCarousel({ events }) {
  const { title } = content.carousel;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
  };

  return (
    <section className='single-carousel'>
      <p className='single-carousel__text mb-9'>{title}</p>
      <Slider {...settings}>
        {events.map((item) => {
          return (
            <Event
              className='event--sm'
              info={item}
              key={item.id}
              disabled={false}
            />
          );
        })}
      </Slider>
    </section>
  );
}
