export const content = {
  faq: {
    title: 'Tire Dúvidas',
  },
  newsLetter: {
    title: 'Inscreva-se e fique por dentro dos próximos eventos e ações Catho',
    btnText: 'Enviar',
    inputNamePlaceholder: 'Seu nome',
    inputEmailPlaceholder: 'Seu e-mail',
    inputTelPlaceholder: 'Seu telefone',
  },
  footer: {
    text:
      'Todos os diretos reservados - Catho Online Ltda. CNPJ: 03.753.088/0001-00',
    footerImg: require('../assets/images/logo-white.svg'),
  },
  arrowTopText: 'TOPO',
  modal: {
    share: {
      shareText: 'Compartilhar:',
      linkText: 'Link:',
      copyToClipboard: 'COPIAR LINK ',
    },
    register: {
      nameText: 'Seu nome:',
      emailText: 'Seu e-mail:',
      telText: 'Seu telefone:',
      terms: 'Declaro que li e aceito os',
      submitBtnText: 'ENVIAR',
    },
    registerFeedback: {
      title: 'Obrigado pela inscrição!',
      text:'Você receberá um alerta em seu e-mail quando tivermos novos conteúdos.',
      btnText: 'FECHAR',
    },
  },
  liveWarning: {
    liveText: 'AO VIVO',
    watchText: 'ASSITIR AGORA',
  },
  header: {
    logo: require('../assets/images/logo.png'),
    logoWhite: require('../assets/images/logo-full-white.svg'),
    link: {
      event: 'O Evento',
      agenda: 'Agenda',
      questions: 'Tire Dúvidas',
    },
    searchBarText: 'Busque por um tema ou palavra-chave',
  },
  register: {
    url: 'https://integration-sfmc-api.crm-marketing.catho.com.br/api/integration-sfmc-api',
    token: '19de4e0f-1421-4706-8758-1617fb7e8f10',
    messages: {
      success: 'Sua inscrição foi realizada com sucesso.',
      error: 'Erro ao enviar o formulário, tente novamente.'
    }
  }
};
